import { Component, OnInit, Input, Output } from '@angular/core';
import { IUser } from 'src/app/interfaces/Iuser';
import { environment } from '../../../environments/environment.prod';

@Component({
  selector: 'app-user-dropdown-menu',
  templateUrl: './user-dropdown-menu.component.html',
  styleUrls: ['./user-dropdown-menu.component.scss'],
})
export class UserDropdownMenuComponent implements OnInit {

  @Input() user: IUser;
  @Input() popover: any;

  public version: string;

  constructor() { }

  ngOnInit() {
    this.version = environment.version;
  }

  dismissPopover() {
    this.popover.dismiss();
  }
}
