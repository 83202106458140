import { Component, OnInit, ViewChild } from '@angular/core';
import { ILicence } from 'src/app/interfaces/Ilicence';
import { Router, NavigationEnd } from '@angular/router';
import { LicenceService } from 'src/app/services/licence.service';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { formatDate } from '@angular/common';

import { ButtonDetailsLicenceComponent } from 'src/app/components/forms/licence/button-details-licence/button-details-licence.component';
import { ButtonRenewLicenceComponent } from 'src/app/components/forms/licence/button-renew-licence/button-renew-licence.component';
//import { ClientService } from 'src/app/services/client.service';

import { ButtonBlockLicenceComponent } from 'src/app/components/forms/licence/button-block-licence/button-block-licence.component';
import { ButtonDeleteLicenceComponent } from 'src/app/components/forms/licence/button-delete-licence/button-delete-licence.component';
import { GridOptions, GridSizeChangedEvent } from 'ag-grid-community';

import { AppInstanceService } from 'src/app/services/app-instance.service';
import { IAppInstance } from 'src/app/interfaces/IappInstance';

export interface Datos{
  _id : String;
  clientAlias : String;
  instance : String;
  validTo : String;
  enabled : String;
  action : String;
  version: String;
}

@Component({
  selector: 'app-admin-licence-list',
  templateUrl: './admin-licence-list.page.html',
  styleUrls: ['./admin-licence-list.page.scss'],
})


export class AdminLicenceListPage implements OnInit {

  gridApi : any;
  columnApi : any;

  loading: boolean;
  licences: ILicence[] = [];

  appInstances : IAppInstance[] = [];
  
  columnDefs = [];
  rowData : Datos[] = [];
  
  frameworkComponents :any;
  defaultColDef: any;

  public gridOptions: GridOptions;
   
  constructor(
    private licenceService: LicenceService,
    private instanceService: AppInstanceService,
    private router: Router
  ) {
    
    
    this.loading = false;
    
    this.columnDefs = [
      {headerName: "Cliente", field:"clientAlias", width: 220},
      {headerName: "ID de Instancia", field:"instance", width:320},
      {headerName: "Versión", field:"version", width:100},
      {headerName: "Válida", field:"validTo", width: 120},
      {headerName: "Deshab.", field:"enabled", width: 100,
                   cellStyle: params =>{
                     if(params.value === 'Si'){
                       return {color:'#dc3545', textAlign: 'center'};
                     }
                     return {textAlign: 'center'};
                   }
                  },
      {headerName: "", field:"_id", cellRenderer:"ButtonDetailsLicence", width: 60,
                   cellStyle:{textAlign: 'center'}},
      {headerName: "", field:"_id", cellRenderer:"ButtonRenewLicence", width: 60,
                   cellStyle:{textAlign: 'center'}},
      {headerName: "", field:"_id", cellRenderer:"ButtonBlockLicence", width: 60,
                   cellStyle:{textAlign: 'center'}},
      {headerName: "", field:"_id", cellRenderer:"ButtonDeleteLicence", width: 60,
                   cellStyle:{textAlign: 'center'}}
                   
    ];

    this.frameworkComponents = {
      ButtonDetailsLicence : ButtonDetailsLicenceComponent,
      ButtonRenewLicence: ButtonRenewLicenceComponent,
      ButtonBlockLicence: ButtonBlockLicenceComponent,
      ButtonDeleteLicence: ButtonDeleteLicenceComponent
    };
         
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true
      
    };
    
    this.licenceService.listen().subscribe((m:any)=>{
      
      this.refresh();
    });
    
  }

  ngOnInit() {    
    this.router.events
      .subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.refresh()
        }
      })
  
  }

  refresh() {
    this.loading = true;
    this.instanceService.getList().subscribe(
      (resp: HttpResponse<any>) => {
        this.appInstances = resp.body.instances;
        this.matrizLicense(this.appInstances);
        this.loading = false;
      },
      (errResp: HttpErrorResponse) => {
        this.loading = false;
        console.error(errResp.error);
      }
    );

  }

  matrizLicense(instances: any) {
    this.rowData=[];
    instances
    .filter((element: any) => element.appLicence)
    .forEach((element: any) => {
      //console.log(element.clientAlias);
      let hasta = formatDate(element.appLicence.licenceParameters.validTo,'dd/MM/yyyy','en-US') ;
      let enabled = element.appLicence.licenceParameters.disabled ? 'Si' : 'No';
      let nroVersion = element.currentVersion ? element.currentVersion : element.appVersion;
      let row : Datos = {
        _id : element.appLicence.id,
        clientAlias: element.appLicence.clientAlias,
        instance: element.instanceId,
        validTo: hasta,
        enabled: enabled,
        action: '0',
        version: nroVersion
      }
      this.rowData.push(row);
    });
    setTimeout(() => {
      this.gridApi.setRowData(this.rowData);  
    }, 50);
    
   
  }

  cerroModal(e){
    console.log(e);
    
  }
  onGridReady(params){
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    
    //console.log('Estoy OnGrid');
    params.api.setColumnDefs(this.columnDefs);
    //params.api.setRowData(this.rowData);

  }
  onGridSizeChanged(event: GridSizeChangedEvent): void {
    event.api.sizeColumnsToFit();
  }
  
  
}

