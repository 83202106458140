import { Component, Output, OnInit, EventEmitter } from '@angular/core';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { ClientService } from '../../../../services/client.service'; 
import { FormControl} from '@angular/forms';
import { Observable} from 'rxjs';
import { map, startWith} from 'rxjs/operators';


export interface IClients{
  _id : String;
  name: String;
  idExt?: String;
  mail?: String;
}

@Component({
  selector: 'app-client-select',
  templateUrl: './client-select.component.html',
  styleUrls: ['./client-select.component.scss'],
})
export class ClientSelectComponent implements OnInit {
  
  @Output() selClient = new EventEmitter();

  public loading: boolean;
  public dataClients: IClients[] = [];
  myControl = new FormControl();
  filteredDataClients: Observable<IClients[]>

  constructor(private clientService : ClientService) { 
    this.getClients();

    setTimeout(() => {
      this.filteredDataClients = this.myControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value))
      );    
    }, 100);
    
   }

  ngOnInit(){}
  
  private _filter(value: string): IClients[]{
    const filterValue = value.toLowerCase();
    return this.dataClients.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  getClients(){
    this.loading = true;
    this.clientService.getAll().subscribe(
      (resp:HttpResponse<any>) => {
        this.dataClients = resp.body.clients;
        this.loading = false;
      },
      (errResp: HttpErrorResponse) => {
        this.loading = false;
        console.error(errResp.error);
      }
    )
  }

  send(client: string){
    //console.log('estoy madando:', client);
    this.selClient.emit(client);
  }
  

}
