export const environment = {
  production: true,
  API_URL: 'http://localhost',
  API_PORT: 9015,
  API_PATH: 'api/v1',
  version: '1.0.12',
  locale: 'es-us',

  get API_BASE_URL() {
    return this.API_URL+':'+this.API_PORT+this.API_PATH
  }
};
