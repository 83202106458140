import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IonicModule } from '@ionic/angular';

import { AdminBasePage } from './admin-base/admin-base.page';
import { AdminHomePage } from './admin-home/admin-home.page'

import { AdminRoutingModule } from './admin-routing.module'
import { ProductListPage } from './product/product-list/product-list.page'
import { ReactiveFormsModule, FormsModule } from '@angular/forms'
import { MainModule } from '../main/main.module'
import { AdminProductNewPage } from './product/admin-product-new/admin-product-new.page'
import { AdminProductShowPage } from './product/admin-product-show/admin-product-show.page'
import { AdminProductEditPage } from './product/admin-product-edit/admin-product-edit.page'
import { ProductFormComponent } from 'src/app/components/forms/product/product-form/product-form.component'
import { ProductEditFormComponent } from 'src/app/components/forms/product/product-edit-form/product-edit-form.component'
import { AdminProductTypeListPage } from './product-type/admin-product-type-list/admin-product-type-list.page'
import { AdminProductTypeEditPage } from './product-type/admin-product-type-edit/admin-product-type-edit.page'
import { ProductTypeEditFormComponent } from 'src/app/components/forms/product-type/product-type-edit-form/product-type-edit-form.component';
import { ProductTypeFormComponent } from 'src/app/components/forms/product-type/product-type-form/product-type-form.component';
import { AdminProductTypeNewPage } from './product-type/admin-product-type-new/admin-product-type-new.page';
import { AdminProductTypeShowPage } from './product-type/admin-product-type-show/admin-product-type-show.page';
import { AdminProductCategoryListPage } from './product-category/admin-product-category-list/admin-product-category-list.page';
import { AdminProductCategoryShowPage } from './product-category/admin-product-category-show/admin-product-category-show.page';
import { AdminProductCategoryEditPage } from './product-category/admin-product-category-edit/admin-product-category-edit.page';
import { AdminProductCategoryNewPage } from './product-category/admin-product-category-new/admin-product-category-new.page';
import { ProductCategoryFormComponent } from 'src/app/components/forms/product-category/product-category-form/product-category-form.component';
import { ProductCategoryEditFormComponent } from 'src/app/components/forms/product-category/product-category-edit-form/product-category-edit-form.component';
import { AdminCompanyListPage } from './company/admin-company-list/admin-company-list.page'
import { AdminCompanyShowPage } from './company/admin-company-show/admin-company-show.page'
import { AdminCompanyEditPage } from './company/admin-company-edit/admin-company-edit.page'
import { AdminCompanyNewPage } from './company/admin-company-new/admin-company-new.page'
import { CompanyFormComponent } from 'src/app/components/forms/company/company-form/company-form.component';
import { CompanyEditFormComponent } from 'src/app/components/forms/company/company-edit-form/company-edit-form.component';
import { AdminUserNewPage } from './user/admin-user-new/admin-user-new.page';
import { AdminUserListPage } from './user/admin-user-list/admin-user-list.page';
import { AdminUserShowPage } from './user/admin-user-show/admin-user-show.page';
import { AdminUserEditPage } from './user/admin-user-edit/admin-user-edit.page';
import { UserAbmFormComponent } from 'src/app/components/forms/user/user-abm-form/user-abm-form.component';
import { UserAbmEditFormComponent } from 'src/app/components/forms/user/user-abm-edit-form/user-abm-edit-form.component';
import { AdminLicenceListPage } from './licence/admin-licence-list/admin-licence-list.page';
import { AdminLicenceNewPage } from './licence/admin-licence-new/admin-licence-new.page';
import { TranslateModule } from '@ngx-translate/core'
import { LicenceFormComponent } from 'src/app/components/forms/licence/licence-form/licence-form.component';
import { AdminLicenceShowPage } from './licence/admin-licence-show/admin-licence-show.page';

import { AgGridModule } from 'ag-grid-angular';
import { ButtonDetailsLicenceComponent } from 'src/app/components/forms/licence/button-details-licence/button-details-licence.component';
import { ClientSelectComponent } from 'src/app/components/forms/client/client-select/client-select.component';
import { ButtonRenewLicenceComponent } from 'src/app/components/forms/licence/button-renew-licence/button-renew-licence.component';
import { AdminLicenceRenewPage } from './licence/admin-licence-renew/admin-licence-renew.page';
import { ButtonDeleteLicenceComponent } from 'src/app/components/forms/licence/button-delete-licence/button-delete-licence.component';
import { ButtonBlockLicenceComponent } from 'src/app/components/forms/licence/button-block-licence/button-block-licence.component';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

import { InstanceSelectComponent } from 'src/app/components/forms/instance/instance-select/instance-select.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    AdminRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    MainModule,
    TranslateModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    AgGridModule.withComponents([ButtonDetailsLicenceComponent, 
                                ButtonRenewLicenceComponent,
                                ButtonDeleteLicenceComponent,
                                ButtonBlockLicenceComponent])
    
  ],
  declarations: [
    AdminBasePage,
    AdminHomePage,

    AdminUserNewPage,
    AdminUserListPage,
    AdminUserShowPage,
    AdminUserEditPage,
    UserAbmFormComponent,
    UserAbmEditFormComponent,

    ProductListPage,
    AdminProductNewPage,
    AdminProductShowPage,
    AdminProductEditPage,
    ProductFormComponent,
    ProductEditFormComponent,

    AdminProductTypeListPage,
    AdminProductTypeNewPage,
    AdminProductTypeShowPage,
    AdminProductTypeEditPage,
    ProductTypeFormComponent,
    ProductTypeEditFormComponent,
    
    AdminProductCategoryListPage,
    AdminProductCategoryNewPage,
    AdminProductCategoryShowPage,
    AdminProductCategoryEditPage,
    ProductCategoryFormComponent,
    ProductCategoryEditFormComponent,
    
    AdminCompanyListPage,
    AdminCompanyNewPage,
    AdminCompanyShowPage,
    AdminCompanyEditPage,
    CompanyFormComponent,
    CompanyEditFormComponent,

    AdminLicenceListPage,
    AdminLicenceNewPage,
    AdminLicenceShowPage,
    AdminLicenceRenewPage,
    LicenceFormComponent,

    ButtonDetailsLicenceComponent,
    ButtonRenewLicenceComponent,
    ButtonDeleteLicenceComponent,
    ButtonBlockLicenceComponent,
    ClientSelectComponent,

    InstanceSelectComponent
    
    
  ]
})
export class AdminModule { }
