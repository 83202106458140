import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { IProduct } from 'src/app/interfaces/Iproduct';
import { ProductService } from 'src/app/services/product.service';

@Component({
  selector: 'app-marketplace-home',
  templateUrl: './marketplace-home.page.html',
  styleUrls: ['./marketplace-home.page.scss'],
})
export class MarketplaceHomePage implements OnInit {

  @Input() products: [IProduct]
  @Input() currentPage: number
  @Input() pageSize: number
  @Input() isLastPage: boolean
  @Input() noFilterMatch: boolean
  @Output() searchTerm = new EventEmitter<string>()
  @Output() pagination = new EventEmitter<string>()

  constructor(
    private productService: ProductService
  ) {}

  ngOnInit() {
    // this.loadProducts()
  }

  loadProducts() {
    this.productService.getAll()
      .subscribe(
        resp => {
          this.products = resp.body.products;  
        },
        err => {
          console.log(err)
        }
      )
  }

  search(event) {
    this.searchTerm.emit(event.target.value)
  }

  // pagination
  nextPage() {
    this.pagination.emit('next');
  }

  prevPage() {
    this.pagination.emit('prev'); 
  }

  productImageSrc(id: string) {
    return "api/v1/products/"+id+"/image";
  }
}
