import { Component, OnInit, EventEmitter } from '@angular/core'
import { IProduct } from 'src/app/interfaces/Iproduct'
import { ProductService } from 'src/app/services/product.service'
import { ActivatedRoute, Router } from '@angular/router'
import { ModalController } from '@ionic/angular'
import { NewProductReviewModalComponent } from 'src/app/components/modals/new-product-review-modal/new-product-review-modal.component'
import { Observable, Subscription } from 'rxjs'
import { AuthService } from 'src/app/services/auth.service'
import { IUser } from 'src/app/interfaces/Iuser'

@Component({
  selector: 'app-marketplace-product-show',
  templateUrl: './marketplace-product-show.page.html',
  styleUrls: ['./marketplace-product-show.page.scss'],
})
export class MarketplaceProductShowPage implements OnInit {

  loading: boolean
  product: IProduct
  newReviewModal: any
  reviewModalData: object
  currentUser: IUser
  userSubscription: Subscription

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private productService: ProductService,
    private modalController: ModalController
  ) {
    this.loading = false
  }

  ngOnInit() {
    this.userSubscription = this.authService.currentUser
      .subscribe(user => {
        this.currentUser = user
      })
    this.loadProduct()
  }

  loadProduct() {
    this.loading = true

    let id = this.route.snapshot.paramMap.get('id')

    this.productService.getProduct(id)
      .subscribe(
        resp => {
          this.product = resp.body.product
          this.loading = false
        },
        err => {
          console.log(err)
          this.loading = false
        })
  }

  downloadProduct() {
    if (!this.currentUser) {
      this.router.navigate(['/login'])
      return
    }

    this.productService.getProductFile(this.product._id)
      .subscribe(
        blob => {
          this.loading = false
          const url = window.URL.createObjectURL(blob)

          // Create a new anchor element
          const a = document.createElement('a');
          
          a.href = url
          // use of substring() is because of the typescript error: "Type 'String' is not assignable to type 'string'.'string' is a primitive, but 'String' is a wrapper object."
          a.download = this.product.fileName.substring(0)
          
          // Click handler that releases the object URL after the element has been clicked
          // This is required for one-off downloads of the blob content
          const clickHandler = (anchor) => {
            setTimeout(() => {
              URL.revokeObjectURL(url);
              anchor.removeEventListener('click', clickHandler)
            }, 150)
          };
          
          a.addEventListener('click', clickHandler, false)
          a.click()
        },
        err => {
          console.log(err)
          this.loading = false
        })
  }

  async presentNewReviewModal() {
    
    let eventEmitter= new EventEmitter()

    eventEmitter.subscribe(submitted => {
      if (submitted) {
        this.refresh()
      }
    })

    this.newReviewModal = await this.modalController.create({
      component: NewProductReviewModalComponent,
      componentProps: {
        product: this.product,
        submitted: eventEmitter
      },
      cssClass: 'centerModal'
    })

    return await this.newReviewModal.present()
  }

  refresh() {
    this.loadProduct()
  }

  productImageSrc(id) {
    return "api/v1/products/"+id+"/image";
  }
}
