import { Component, OnInit, Output, EventEmitter } from '@angular/core'
import { FormBuilder, Validators } from '@angular/forms'
import { UserService } from '../../../../services/user.service'
import { Router } from '@angular/router'
import { ModalController } from '@ionic/angular'
import { LoadingModalComponent } from 'src/app/components/modals/loading-modal/loading-modal.component'
import { IUser } from 'src/app/interfaces/Iuser'

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss'],
})
export class UserFormComponent implements OnInit {
  
  @Output() success = new EventEmitter<IUser>()
  
  loading: boolean
  loadingModal: HTMLIonModalElement
  
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private userService: UserService,
    private modalController: ModalController
  ) {
      this.loading = false
    }

  userForm = this.fb.group({
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    email: ['', [
      Validators.required,
      Validators.email
    ]],
    password: ['', Validators.compose([Validators.required, Validators.minLength(8), Validators.maxLength(20)])]
  })

  ngOnInit() {}

  onSubmit() {
    this.loading = true
    this.presentLoadingModal()
      .then(() => {
        const newUser = {
          'user': this.userForm.value
        }
        this.userService.register(newUser)
          .subscribe(
            resp => {
              this.loading = false
              this.loadingModal.dismiss()
              
              if (resp.status == 201) {
                this.onSuccess(resp.body.user)
              }
            },
            err => {
              this.loading = false
              this.loadingModal.dismiss()
              console.log(err)
            }
          )
      })
  }

  onSuccess(user: IUser) {
    this.success.emit(user)
  }

  async presentLoadingModal() {
    this.loadingModal = await this.modalController.create({
      component: LoadingModalComponent,
      backdropDismiss: false,
      keyboardClose: false,
      cssClass: 'loading-modal'
    })
    return await this.loadingModal.present()
  }
}
