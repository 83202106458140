import { Component, OnInit, ViewChild } from '@angular/core'
import { CompanyFormComponent } from 'src/app/components/forms/company/company-form/company-form.component'

@Component({
  selector: 'app-admin-company-new',
  templateUrl: './admin-company-new.page.html',
  styleUrls: ['./admin-company-new.page.scss'],
})
export class AdminCompanyNewPage implements OnInit {

  @ViewChild(CompanyFormComponent)
  form: CompanyFormComponent
  
  constructor(
  ) { }

  ngOnInit() {
  }

  submitForm() {
    this.form.onSubmit()
  }
}
