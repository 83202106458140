import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '../../environments/environment'
import { BehaviorSubject, Observable } from 'rxjs';
import { IUser } from '../interfaces/Iuser';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  
  private currentUserSubject: BehaviorSubject<IUser>
  public currentUser: Observable<IUser>
  
  private host: string
  private base_url: string

  constructor(private http: HttpClient) {
    // this.base_url = environment.API_BASE_URL
    this.host = window.location.href.split('#', 1)[0]
    this.base_url = this.host + environment.API_PATH

    this.currentUserSubject = new BehaviorSubject<IUser>(JSON.parse(localStorage.getItem('currentUser')))
    this.currentUser = this.currentUserSubject.asObservable()
  }

  public get currentUserValue(): IUser {
      return this.currentUserSubject.value
  }

  login(payload) {
    return this.http.post(this.base_url + '/tokens', payload)
  }

  updateUser(user: IUser) {
    localStorage.setItem('currentUser', JSON.stringify(user))  
    this.currentUserSubject.next(user)
  }

  setSession(loginResp) {
    localStorage.setItem('currentUser', JSON.stringify(loginResp.user))  
    localStorage.setItem('id_token', loginResp.token)
    this.currentUserSubject.next(loginResp.user)
  }

  async logout() {
    localStorage.removeItem('currentUser')
    localStorage.removeItem('id_token')
    await this.currentUserSubject.next(null)
  }

  public isLoggedIn() {
    return localStorage.getItem('id_token') ? true : false
  }
}