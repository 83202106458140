import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ProductService } from '../../../../services/product.service'
import { ProductTypeService } from '../../../../services/product-type.service'
import { ProductCategoryService } from '../../../../services/product-category'
import { CompanyService } from '../../../../services/company.service'
import { Router } from '@angular/router';
import { ToastController, IonInput } from '@ionic/angular';
import { IProductType } from 'src/app/interfaces/IproductType'
import { IProductCategory } from 'src/app/interfaces/IproductCategory'
import { ICompany } from 'src/app/interfaces/Icompany';
import { IProduct } from 'src/app/interfaces/Iproduct';

@Component({
  selector: 'app-product-form',
  templateUrl: './product-form.component.html',
  styleUrls: ['./product-form.component.scss'],
})
export class ProductFormComponent implements OnInit {

  @ViewChild('inputChip') inputChip //: ElementRef<IonInput>
  
  loading: Boolean
  products: Array<IProduct>
  productTypes: Array<IProductType>
  productCategories: Array<IProductCategory>
  companies: Array<ICompany>
  vsProducts: Array<String>

  tags: Array<string>

  alertOptions: any = {
    cssClass: 'wideAlert'
  }

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private productService: ProductService,
    private productTypeService: ProductTypeService,
    private productCategoryService: ProductCategoryService,
    private companyService: CompanyService,
    private toastController: ToastController
  ) {
    this.loading = false
    this.vsProducts = [
      'Suite',
      'Data Spa',
      'Data Analytics',
      'Presentation',
      'EPM'
    ]
    this.tags = []
  }

  productForm = this.fb.group({
    title: ['', Validators.required],
    description: ['', Validators.required],
    image: [null],
    product: ['', Validators.required],
    type: [null, Validators.required],
    category: [null, Validators.required],
    company: [null, Validators.required],
    version: ['', Validators.required],
    dependencies: [null],
    price: [null, Validators.compose([Validators.required, Validators.min(0)])],
    tags: [this.tags],
    active: [false, Validators.required],
    file: [null]
  });
  
  onImageSelect(event) {
    if (event.target.files.length > 0) {
      const img = event.target.files[0];
      this.productForm.get('image').setValue(img);
    }
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.productForm.get('file').setValue(file);
    }
  }

  ngOnInit() {
    this.loadProducts()
    this.loadProductTypes()
    this.loadProductCategories()
    this.loadCompanies()
  }

  onSubmit() {
    this.loading = true
  
    const newProduct = {
      'product': this.productForm.value
    }
    
    newProduct.product.file = ''
    
    const formData = new FormData();
    
    formData.append('file', this.productForm.get('file').value)
    formData.append('image', this.productForm.get('image').value)
    formData.append('payload', JSON.stringify(newProduct))

    this.productService.newProduct(formData)
      .subscribe(resp => {
        if (resp.status == 201) {
          this.presentCreatedToast()
          this.router.navigate(['/admin/products', resp.body.product._id])
        }
      }, error => {
        this.presentErrorToast()
      })
  }

  async presentCreatedToast() {
    const successToast = await this.toastController.create({
      message: 'Producto creado exitosamente',
      duration: 2200,
      color: 'tertiary',
      showCloseButton: true,
      closeButtonText: 'cerrar'
    });
    successToast.present()
  }

  async presentErrorToast() {
    const errorToast = await this.toastController.create({
      message: 'Se produjo un error al crear el nuevo producto, inténtelo nuevamente',
      duration: 0,
      color: 'danger',
      showCloseButton: true,
      closeButtonText: 'cerrar'
    });
    errorToast.present()
  }

  loadProducts() {
    this.productService.getAll()
      .subscribe(resp => {
        this.products = resp.body.products
      })
  }

  loadProductTypes() {
    this.productTypeService.getAll()
      .subscribe(resp => {
        this.productTypes = resp.body.productTypes
      })
  }

  loadProductCategories() {
    this.productCategoryService.getAll()
      .subscribe(resp => {
        this.productCategories = resp.body.productCategories
      })
  }

  loadCompanies() {
    this.companyService.getAll()
      .subscribe(resp => {
        this.companies = resp.body.companies
      })
  }

  removeChip(id: number): void {
    this.tags.splice(id, 1)
    this.productForm.controls['tags'].setValue(this.tags)
  }
  
  addChip(chip: string): void {
    if (chip.trim().length > 0) {
      chip = chip.toLowerCase()
      this.tags.push(chip)
      this.productForm.controls['tags'].setValue(this.tags)
      
      this.inputChip.getInputElement()
        .then(input => {
          input.value = ''
        })
    }
  }
}
