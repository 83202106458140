import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { ProductService } from 'src/app/services/product.service';
import { Observable } from 'rxjs';
import { IProduct } from 'src/app/interfaces/Iproduct';
import { ProductEditFormComponent } from 'src/app/components/forms/product/product-edit-form/product-edit-form.component';

@Component({
  selector: 'app-admin-product-edit',
  templateUrl: './admin-product-edit.page.html',
  styleUrls: ['./admin-product-edit.page.scss'],
})
export class AdminProductEditPage implements OnInit {

  @ViewChild(ProductEditFormComponent)
  form: ProductEditFormComponent
  
  loading: Boolean
  product_id: String

  constructor(
    private route: ActivatedRoute
  ) {
    this.product_id = this.route.snapshot.paramMap.get('id')
  }

  ngOnInit() {
  }
  
  submitForm() {
    this.form.onSubmit()
  }
}
