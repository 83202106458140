import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ProductCategoryService } from '../../../../services/product-category'
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';

@Component({
  selector: 'app-product-category-form',
  templateUrl: './product-category-form.component.html',
  styleUrls: ['./product-category-form.component.scss'],
})
export class ProductCategoryFormComponent implements OnInit {

  loading: Boolean

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private productCategoryService: ProductCategoryService,
    private toastController: ToastController
  ) {
    this.loading = false
  }

  productCategoryForm = this.fb.group({
    name: ['', Validators.required]
  })

  ngOnInit() {}

  onSubmit() {
    this.loading = true
  
    const newProductCategory = {
      'productCategory': this.productCategoryForm.value
    }

    this.productCategoryService.create(newProductCategory)
      .subscribe(resp => {
        if (resp.status == 201) {
          this.presentCreatedToast()
          console.log('alskdj')
          this.router.navigate(['/admin/product-categories', resp.body.productCategory._id])
        }
      }, error => {
        // default error message
        let message = 'Se produjo un error al crear la nueva categoría de producto, inténtelo nuevamente';
        
        // duplicate key
        if (error.status == 409) {
          message = 'El nombre ingresado ya se encuentra registrado.'  
        }
        this.presentErrorToast(message)
      })
  }

  async presentCreatedToast() {
    const successToast = await this.toastController.create({
      message: 'Categoría de Producto creada exitosamente',
      duration: 2200,
      color: 'tertiary',
      showCloseButton: true,
      closeButtonText: 'cerrar'
    });
    successToast.present()
  }

  async presentErrorToast(message: string) {
    const errorToast = await this.toastController.create({
      message: message,
      duration: 0,
      color: 'danger',
      showCloseButton: true,
      closeButtonText: 'cerrar'
    });
    errorToast.present()
  }
}
