import { Component, OnInit, Input } from '@angular/core';
import { ModalController, NavController, NavParams } from '@ionic/angular';


@Component({
  selector: 'app-admin-licence-block',
  templateUrl: './admin-licence-block.page.html',
  styleUrls: ['./admin-licence-block.page.scss'],
})
export class AdminLicenceBlockPage implements OnInit {

  @Input() habilitado : boolean;
  @Input() client : string;
  @Input() instance : string;
  estado : boolean;

  constructor(private modalCtrl: ModalController, public navCtrl: NavController) {
    this.estado = this.habilitado;
    //this.estado = true;
   }

  ngOnInit() {
    
  }
  myChange(e){
    //console.log(e.detail.checked);
    this.habilitado=e.detail.checked;
  }
  saveModal(){
    //this.habilitado = this.estado;
    this.modalCtrl.dismiss({
      habilitado: this.habilitado
    })
  }
  closeModal(){
    this.modalCtrl.dismiss(
      'sin datos'
    );
  }
}
