import { Component, OnInit, Output, EventEmitter } from '@angular/core'
import { FormBuilder, Validators } from '@angular/forms'
import { UserService } from '../../../../services/user.service'
import { Router } from '@angular/router'
import { ModalController, ToastController } from '@ionic/angular'
import { LoadingModalComponent } from 'src/app/components/modals/loading-modal/loading-modal.component'
import { IUser } from 'src/app/interfaces/Iuser'

@Component({
  selector: 'app-user-abm-form',
  templateUrl: './user-abm-form.component.html',
  styleUrls: ['./user-abm-form.component.scss'],
})
export class UserAbmFormComponent implements OnInit {
  
  @Output() success = new EventEmitter<IUser>()
  
  loading: Boolean
  loadingModal: HTMLIonModalElement
  roles: Array<string>
  
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private userService: UserService,
    private modalController: ModalController,
    private toastController: ToastController
  ) {
    this.loading = false
    this.roles = [
      'user',
      'admin'
    ]
  }

  userForm = this.fb.group({
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    email: ['', [
      Validators.required,
      Validators.email
    ]],
    role: [null, Validators.required]
    //password: ['', Validators.required]
  })
  
  ngOnInit() {}

  onSubmit() {
    this.loading = true
    this.presentLoadingModal()
      .then(() => {
        const newUser = {
          'user': this.userForm.value
        }
        this.userService.create(newUser)
          .subscribe(
            resp => {
              this.loading = false
              this.loadingModal.dismiss()
              
              if (resp.status == 201) {
                this.presentCreatedToast()
                this.router.navigate(['/admin/users', resp.body.user._id])
              }
            },
            err => {
              this.loading = false
              this.loadingModal.dismiss()
              this.presentErrorToast()
            }
          )
      })
  }

  onSuccess(user: IUser) {
    this.success.emit(user)
  }

  async presentLoadingModal() {
    this.loadingModal = await this.modalController.create({
      component: LoadingModalComponent,
      backdropDismiss: false,
      keyboardClose: false,
      cssClass: 'loading-modal'
    })
    return await this.loadingModal.present()
  }

  async presentCreatedToast() {
    const successToast = await this.toastController.create({
      message: 'Usuario creado exitosamente',
      duration: 2200,
      color: 'tertiary',
      showCloseButton: true,
      closeButtonText: 'cerrar'
    });
    successToast.present()
  }

  async presentErrorToast() {
    const errorToast = await this.toastController.create({
      message: 'Se produjo un error al crear el nuevo usuario, inténtelo nuevamente',
      duration: 0,
      color: 'danger',
      showCloseButton: true,
      closeButtonText: 'cerrar'
    });
    errorToast.present()
  }
}
