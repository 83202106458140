import { Component, OnInit, ViewChild } from '@angular/core';
import { ProductTypeFormComponent } from 'src/app/components/forms/product-type/product-type-form/product-type-form.component';

@Component({
  selector: 'app-admin-product-type-new',
  templateUrl: './admin-product-type-new.page.html',
  styleUrls: ['./admin-product-type-new.page.scss'],
})
export class AdminProductTypeNewPage implements OnInit {

  @ViewChild(ProductTypeFormComponent)
  form: ProductTypeFormComponent
  
  constructor(
  ) { }

  ngOnInit() {
  }

  submitForm() {
    this.form.onSubmit()
  }
}
