import { Component, OnInit, ViewChild } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { FormBuilder } from '@angular/forms'
import { ProductTypeService } from 'src/app/services/product-type.service'
import { Observable } from 'rxjs'
import { IProductType } from 'src/app/interfaces/IproductType'
import { ProductTypeEditFormComponent } from 'src/app/components/forms/product-type/product-type-edit-form/product-type-edit-form.component'

@Component({
  selector: 'app-admin-product-type-edit',
  templateUrl: './admin-product-type-edit.page.html',
  styleUrls: ['./admin-product-type-edit.page.scss'],
})
export class AdminProductTypeEditPage implements OnInit {

  @ViewChild(ProductTypeEditFormComponent)
  form: ProductTypeEditFormComponent
  
  loading: Boolean
  productTypeId: String

  constructor(
    private route: ActivatedRoute
  ) {
    this.productTypeId = this.route.snapshot.paramMap.get('id')
  }

  ngOnInit() {
  }
  
  submitForm() {
    this.form.onSubmit()
  }
}
