import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { MainHomePage } from './pages/main/main-home/main-home.page';
import { NotFoundPage } from './pages/main/errors/not-found/not-found.page';

const routes: Routes = [
  {
    path: '',
    component: MainHomePage,
    pathMatch: 'full'
  },
  {
    path: '**',
    component: NotFoundPage
  }
  /*
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'list',
    loadChildren: () => import('./list/list.module').then(m => m.ListPageModule)
  }
  */
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, useHash: true })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
