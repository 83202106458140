import { Component, Input, OnInit, Output } from '@angular/core';
import { ModalController, NavController, NavParams } from '@ionic/angular';


@Component({
  selector: 'app-admin-client-new',
  templateUrl: './admin-client-new.page.html',
  styleUrls: ['./admin-client-new.page.scss'],
})
export class AdminClientNewPage implements OnInit {

  @Input() name : string;
  @Input() mail : string;
  @Input() idExt : string;

  new_name: string = "";
  new_mail: string = "";
  new_idExt: string = "";

  constructor(private modalCtrl: ModalController, public navCtrl: NavController) { }
  
  
  ngOnInit() {
  }

  changeName(valor){
      this.name = valor;
  }
  changeMail(valor){
    this.mail = valor;
  }
  changeIdExt(valor){
    this.idExt = valor;
}

  saveModal(){
    this.modalCtrl.dismiss({
      name:this.name,
      mail:this.mail,
      idExt:this.idExt
    });
  
    
  }
  closeModal(){
    this.modalCtrl.dismiss(
      'sin datos'
    );
  
    
  }

}
