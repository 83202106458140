import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IProduct } from 'src/app/interfaces/Iproduct';
import { FormBuilder, Validators } from '@angular/forms';
import { ProductService } from 'src/app/services/product.service';
import { AuthService } from 'src/app/services/auth.service';
import { ModalController } from '@ionic/angular';
import { LoadingModalComponent } from '../loading-modal/loading-modal.component';

@Component({
  selector: 'app-new-product-review-modal',
  templateUrl: './new-product-review-modal.component.html',
  styleUrls: ['./new-product-review-modal.component.scss'],
})
export class NewProductReviewModalComponent implements OnInit {

  @Input() product: IProduct
  @Output() submitted = new EventEmitter<boolean>()

  loadingModal: HTMLIonModalElement

  constructor(
    private fb: FormBuilder,
    private productService: ProductService,
    private authService: AuthService,
    private modalController: ModalController
  ) { }

  reviewForm = this.fb.group({
    user_id: [''],
    user_name: [''],
    score: [null, [
      Validators.required,
      Validators.min(0),
      Validators.max(5)
    ]],
    comment: ['']
  })


  ngOnInit() {}

  onSubmit() {

    this.presentLoadingModal()
      .then(() => {
        // set current user id in review form
        this.reviewForm.controls['user_id'].setValue(this.authService.currentUserValue._id)
        this.reviewForm.controls['user_name'].setValue(this.authService.currentUserValue.firstName)
        
        const payload = {
          review: this.reviewForm.value
        }

        this.productService.newReview(this.product._id, payload)
          .subscribe(
            resp => {
              this.loadingModal.dismiss()
                .then(() => {
                  this.dismiss()
                })
              this.submitted.emit(true)
            },
            err => {
              this.loadingModal.dismiss()
              .then(() => {
                this.dismiss()
              })
              console.log(err)
            })
          })
  }

  dismiss() {
    this.modalController.dismiss()
  }

  async presentLoadingModal() {
    this.loadingModal = await this.modalController.create({
      component: LoadingModalComponent,
      backdropDismiss: false,
      keyboardClose: false,
      cssClass: 'loading-modal'
    })
    return await this.loadingModal.present()
  }
}
