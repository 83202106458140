import { Component, OnInit } from '@angular/core';
import { IProduct } from '../../../../interfaces/Iproduct'
import { ProductService } from '../../../../services/product.service'
import { Router, NavigationEnd } from '@angular/router';
import { ToastController, ModalController, AlertController } from '@ionic/angular';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.page.html',
  styleUrls: ['./product-list.page.scss'],
})
export class ProductListPage implements OnInit {

  loading: Boolean
  products: IProduct[]

  constructor(
    private productService: ProductService,
    private router: Router  ,
    private toastController: ToastController,
    private alertController: AlertController
  ) {
    this.loading = false
  }

  ngOnInit() {    
    this.router.events
      .subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.refresh()
        }
      })
  }

  refresh() {
    this.loading = true
    
    this.productService.getAll()
    .subscribe(resp => {
      this.products = resp.body.products
      this.loading = false
    })    
  }

  deleteProduct(id) {
    this.productService.deleteProduct(id)
      .subscribe(resp => {
        if (resp.status == 200) {
          this.presentDeletedToast()
          this.refresh()
        } else {
          console.log(resp)
        }
      })
  }

  async presentConfirm(product: IProduct): Promise<any> {
    return new Promise(async (resolve) => {
      const alert = await this.alertController.create({
        header: 'Confirmar',
        message: 'Confirma que deséa eliminar el producto "'+product.title+'"?',
        buttons: [
          {
            text: 'Cancelar',
            role: 'cancel',
            cssClass: 'secondary',
            handler: (cancel) => {
              resolve(false);
            }
          }, {
            text: 'Eliminar',
            handler: (ok) => {
              resolve(true);
            }
          }
        ]
      })
      alert.present()
    })
  }

  onDelete(event, product) {
      this.presentConfirm(product)
      .then(confirm => {
        if (confirm) {
          this.deleteProduct(product._id)
        }
      })
  }

  async presentDeletedToast() {
    const toast = await this.toastController.create({
      message: 'Producto eliminado correctamente',
      duration: 2500,
      color: 'tertiary',
      showCloseButton: true,
      closeButtonText: 'cerrar'
    });
    toast.present()
  }
}
