import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { RouteReuseStrategy } from '@angular/router'

import { IonicModule, IonicRouteStrategy } from '@ionic/angular'
import { SplashScreen } from '@ionic-native/splash-screen/ngx'
import { StatusBar } from '@ionic-native/status-bar/ngx'
import { HttpClientModule, HttpClient } from '@angular/common/http'

import { NgProgressModule } from 'ngx-progressbar'
// import { NgProgressHttpModule } from 'ngx-progressbar/http'
import { NgProgressRouterModule } from 'ngx-progressbar/router'

import { AppComponent } from './app.component'
import { AppRoutingModule } from './app-routing.module'

import { AdminModule } from './pages/admin/admin.module'
import { MainModule } from './pages/main/main.module'

import { HTTP_INTERCEPTORS } from '@angular/common/http'
import { MainInterceptor } from './http-interceptors/main-interceptor'
import { UserDropdownMenuComponent } from './components/user-dropdown-menu/user-dropdown-menu.component'

import { TranslateModule, TranslateLoader } from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'

import { AgGridModule } from 'ag-grid-angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    UserDropdownMenuComponent
    
  ],
  entryComponents: [
    UserDropdownMenuComponent
  ],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    MainModule,
    AdminModule,
    AppRoutingModule,
    HttpClientModule,
    NgProgressModule.withConfig({
      spinner: false,
      color: '#B9E614'
    }),
    NgProgressRouterModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: (createTranslateLoader),
          deps: [HttpClient]
      }
    }),
    AgGridModule.withComponents([]),
    BrowserAnimationsModule
    
  ],
  exports: [
    TranslateModule
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: MainInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
