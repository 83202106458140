import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { ModalController, ToastController } from '@ionic/angular'
import { LoadingModalComponent } from 'src/app/components/modals/loading-modal/loading-modal.component'
import { LicenceService } from 'src/app/services/licence.service';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { ILicence } from 'src/app/interfaces/Ilicence';

import { IClient } from 'src/app/interfaces/Iclient';
import { ClientService } from 'src/app/services/client.service';
import { Router, NavigationEnd } from '@angular/router';
import { AdminClientNewPage } from '../../../../pages/admin/client/admin-client-new/admin-client-new.page';


@Component({
  selector: 'app-licence-form',
  templateUrl: './licence-form.component.html',
  styleUrls: ['./licence-form.component.scss'],
})
export class LicenceFormComponent implements OnInit {
  

  @Output() created = new EventEmitter<ILicence>()

  loading: boolean = false;
  loadingModal: HTMLIonModalElement;

  licenceDateFrom: string;
  licenceDateTo: string;

  public clients: IClient[] = [];

  id_licence : string = '';
  
  selectActive : boolean = true;
  showNew :boolean = false;

  constructor(
    private fb: FormBuilder,
    private modalController: ModalController,
    private licenceService: LicenceService,
    private toastController: ToastController,
    private clientService : ClientService,
    private router: Router,
    private modalCtrl: ModalController
    
  ) { }

  licenceForm: FormGroup = this.fb.group({
    instance: [null, Validators.required],
    clientAlias: ['', Validators.required],
    licenceParameters: this.fb.group({
      usersFull: [null, Validators.required],
      usersViewer: [null, Validators.required],
      enabledModules: [[]],
      validFrom: [null, Validators.required],
      validTo: [null, Validators.required],
      tolerance: [30, Validators.required],
      disabled: [false, Validators.required]
    })
  });

  ngOnInit() {
    const now: Date = new Date();
    const fromDate: Date = new Date(now.getFullYear(), now.getMonth() - 1);
    const toDate: Date = new Date(now.getFullYear() + 5, 11, 31);
    this.licenceDateFrom = fromDate.toISOString();
    this.licenceDateTo = toDate.toISOString();

  }
  
  
  onSubmit() {
    this.loading = true;
    this.presentLoadingModal()
      .then(() => {
        const payload = {
          'licence': this.licenceForm.value
        };
        this.licenceService.create(payload)
          .subscribe(
            (resp: HttpResponse<any>) => {
              this.loading = false;
              this.loadingModal.dismiss();
              
              if (resp.status === 201) {
                this.onCreated(resp.body.created);
                this.presentToast(
                  'Licencia creada exitosamente',
                  'success',
                  5000
                );
              }
            },
            (err: HttpErrorResponse) => {
              this.loading = false;
              this.loadingModal.dismiss();
              if (err.status === 404) {
                this.presentToast(
                  'No se encontró ninguna instancia con el id de instancia ingresado',
                  'danger',
                  0
                );
              } else if (err.status === 403) {
                this.presentToast(
                  'Su usuario no tiene los permisos necesarios para crear licencias',
                  'danger',
                  0
                );
              } else {
                console.error(err.error);
                this.presentToast(
                  'Se produjo un error inesperado al intentar crear la licencia',
                  'danger',
                  0
                );
              }
            }
          )
      })
  }

  onCreated(licence: ILicence) {
    this.created.emit(licence);
  }

  async presentLoadingModal() {
    this.loadingModal = await this.modalController.create({
      component: LoadingModalComponent,
      backdropDismiss: false,
      keyboardClose: false,
      cssClass: 'loading-modal'
    });
    return await this.loadingModal.present();
  }

  async presentToast(message: string, color: string, duration: number) {
    const toast = await this.toastController.create({
      message: message,
      duration: duration,
      color: color,
      showCloseButton: true,
      closeButtonText: 'cerrar'
    });
    toast.present()
  }
  // -  Clients ---//
  
  async openModal(){
    const modal = await this.modalCtrl.create({
      component: AdminClientNewPage,
      componentProps: {
          name: '',
          mail: '',
          idExt:''      
      },
      
    });

    modal.onDidDismiss().then((data) => {
      if(data.data!='sin datos'){
          let newName=data.data.name;
          
          let datos = {'client': data.data};
          this.loading = true;
          this.clientService.create(datos).subscribe(resp => {
            if(resp.status == 201){
              this.licenceForm.patchValue({clientAlias: newName});
              this.presentCreatedToast();
              this.selectActive=false;
              this.showNew=true;
              
            }

        }, error =>{
          let message = 'Se produjo un error al crear el nuevo Cliente, inténtelo nuevamente';
            if(error.status == 409){
              message = 'El Cliente ingresado ya se encuentra registrado.'
            }
          this.presentErrorToast(message)
        });
      }
    })
    
    
    await modal.present();

  }
  async presentCreatedToast() {
    const successToast = await this.toastController.create({
      message: 'Cliente creado exitosamente',
      duration: 2200,
      color: 'success',
      showCloseButton: true,
      closeButtonText: 'cerrar'
    });
    successToast.present()
  }

  async presentErrorToast(message: string) {
    const errorToast = await this.toastController.create({
      message: message,
      duration: 0,
      color: 'danger',
      showCloseButton: true,
      closeButtonText: 'cerrar'
    });
    errorToast.present()
  }

  getCli(e){
    this.licenceForm.patchValue({clientAlias: e});
  }
  ///// Instances

  getSel(e){
    this.licenceForm.patchValue({instance: e});
  }
  
  
}
