import { Component, OnInit } from '@angular/core';
import { ProductCategoryService } from 'src/app/services/product-category';
import { IProductCategory } from 'src/app/interfaces/IproductCategory';
import { ProductTypeService } from 'src/app/services/product-type.service';
import { CompanyService } from 'src/app/services/company.service';
import { IProductType } from 'src/app/interfaces/IproductType';
import { ICompany } from 'src/app/interfaces/Icompany';
import { IProduct } from 'src/app/interfaces/Iproduct';
import { ProductService } from 'src/app/services/product.service';

@Component({
  selector: 'app-marketplace-index',
  templateUrl: './marketplace-index.page.html',
  styleUrls: ['./marketplace-index.page.scss'],
})
export class MarketplaceIndexPage implements OnInit {

  productCategories: Array<IProductCategory>
  productTypes: Array<IProductType>
  companies: Array<ICompany>
  products: Array<IProduct>
  
  filteredProducts: Array<IProduct>

  selectedProducts: Array<String>
  selectedCompanies: Array<String>
  selectedTypes: Array<String>
  selectedCategories: Array<String>
  
  // pagination
  currentPage: number;
  pageSize: number;
  isLastPage: boolean;

  vsProducts: Array<string>

  noFilterMatch: boolean

  constructor(
    private categoryService: ProductCategoryService,
    private productTypeService: ProductTypeService,
    private companyService: CompanyService,
    private productService: ProductService
  ) {
    this.selectedProducts = []
    this.selectedTypes = []
    this.selectedCategories = []
    this.selectedCompanies = []

    this.currentPage = 1;
    this.pageSize = 6;
    this.isLastPage = false;

    this.noFilterMatch = false;
  }

  ngOnInit() {
    this.loadCategories()
    this.loadProductTypes()
    this.loadCompanies()
    this.loadProducts()
  }

  loadCategories() {
    this.categoryService.getAll()
      .subscribe(
        resp => {
          this.productCategories = resp.body.productCategories
        },
        err => {
          console.log(err)
        }
      )
  }

  loadProductTypes() {
    this.productTypeService.getAll()
      .subscribe(
        resp => {
          this.productTypes = resp.body.productTypes
        },
        err => {
          console.log(err)
        }
      )
  }

  loadCompanies() {
    this.companyService.getAll()
      .subscribe(
        resp => {
          this.companies = resp.body.companies
        },
        err => {
          console.log(err)
        }
      )
  }

  loadProducts() {
    let params = this.generateQueryParams()

    this.productService.getAll(params)
      .subscribe(
        resp => {
          if (resp.body.products.length < this.pageSize) {
            this.isLastPage = true;
          } else {
            this.isLastPage = false;
          }
          // if no elements returned, update view
          if (resp.body.products.length == 0
            && (this.selectedTypes.length || this.selectedProducts.length || this.selectedCategories || this.selectedCompanies)
            ) {
              this.noFilterMatch = true;
          } else {
            this.noFilterMatch = false;
          }      
          this.products = resp.body.products;
          this.filteredProducts = this.products;
        },
        err => {
          console.log(err)
        }
      )
  }

  generateQueryParams() {
    // pagination
    let params = '?currentPage='+this.currentPage+'&pageSize='+this.pageSize;
    
    this.selectedTypes.forEach(id => {
      params += '&filters[type._id]=' + id
    })
    this.selectedProducts.forEach(name => {
      params += '&filters[product]=' + name
    })
    this.selectedCategories.forEach(id => {
      params += '&filters[category._id]=' + id
    })
    this.selectedCompanies.forEach(id => {
      params += '&filters[company._id]=' + id
    })
    
    return params
  }

  filterTypes(event) {
    this.selectedTypes = event
    this.loadProducts()
  }

  filterCategories(event) {
    this.selectedCategories = event
    this.loadProducts()
  }

  filterCompanies(event) {
    this.selectedCompanies = event
    this.loadProducts()
  }

  filterProducts(event) {
    this.selectedProducts = event
    this.loadProducts()
  }

  searchElements(event) {
    this.filteredProducts = this.products.filter(product => {
      if (event == '') {
        return true
      }
      return product.title.toLowerCase().indexOf(event.toLowerCase()) !== -1
    })
    if (!this.filteredProducts.length) {
      this.noFilterMatch = true;
    } else {
      this.noFilterMatch = false;
    }
  }

  paginate(event) {
    if (event == 'next') {
      this.nextPage()
    }
    if (event == 'prev') {
      this.prevPage()
    }
  }

  // pagination
  nextPage() {
    if (!this.isLastPage) {
      this.currentPage += 1;
      this.loadProducts();
    }
    this.clearSerchBar();
  }

  prevPage() {
    if (this.currentPage < 2) {
      this.currentPage = 1;
    } else {
      this.currentPage -= 1;
    }
    this.clearSerchBar();
    this.loadProducts();
  }

  clearSerchBar() {
    let searchbar = document.querySelector('ion-searchbar');

    searchbar.getInputElement()
      .then((input: HTMLInputElement) => {
        input.value = '';
      })
  }
}
