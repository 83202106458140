import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-button-details-licence',
  templateUrl: './button-details-licence.component.html',
  styleUrls: ['./button-details-licence.component.scss'],
})
export class ButtonDetailsLicenceComponent implements AgRendererComponent {
  
  private cellValue: String;
  
  constructor(private router: Router){}

  refresh(params: ICellRendererParams){
    return true;
  }  

  agInit(params: ICellRendererParams): void{
    this.cellValue = this.getValueToDisplay(params);
  }
  
  buttonClicked(url){
    let id =`${this.cellValue}`;

    let newurl=  `${url}/${id}`;
    this.router.navigateByUrl(newurl);
      
  }
  getValueToDisplay(params: ICellRendererParams) {
    return params.valueFormatted ? params.valueFormatted : params.value;
  }
}
