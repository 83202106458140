import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { ICellRendererParams } from 'ag-grid-community';
import { LicenceService } from 'src/app/services/licence.service';
import { ILicence } from 'src/app/interfaces/Ilicence';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';

import { ModalController, ToastController } from '@ionic/angular';
import { AlertController } from '@ionic/angular';

@Component({
  selector: 'app-button-delete-licence',
  templateUrl: './button-delete-licence.component.html',
  styleUrls: ['./button-delete-licence.component.scss'],
})
export class ButtonDeleteLicenceComponent implements OnInit {

  private cellValue : string;
  loading: Boolean;
  licence: ILicence;
  id:string;

  constructor(private alertControl : AlertController,
    private licenceService: LicenceService,
    private toastController: ToastController) {
              this.loading = false;
              this.licence = null;
     }
  
  async presentAlert() {
      const alert = await this.alertControl.create({
        cssClass: 'my-custom-class',
        header: 'Cliente: '+this.licence.clientAlias,
        subHeader: 'Instancia: '+this.licence.instance,
        message: 'Está seguro de eliminar ésta licencia?',
        buttons: [{
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            //console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Eliminar',
          handler: () => {
            this.dell();
          }
        }]
      });
  
      await alert.present();
  
      const { role } = await alert.onDidDismiss();
      console.log('onDidDismiss resolved with role', role);
  }
  
  ngOnInit() {}

  refresh(params: ICellRendererParams){
    return true;
  }  
  agInit(params: ICellRendererParams): void{
    this.cellValue = this.getValueToDisplay(params);
  }

  dell(){
    
    this.licenceService.delete(this.id).subscribe(resp =>{
      if(resp.status == 200){
         this.presentCreatedToast('Licencia Borrada',"success");
         
         }
     });
     
    this.licenceService.filter('cambia');
  }
  buttonClicked(){
    this.id =`${this.cellValue}`;
    this.licenceService.getOneById(this.id).subscribe(
      (resp: HttpResponse<any>) => {
        this.licence = resp.body.licence;
        this.presentAlert();

        },
        (errResp: HttpErrorResponse) => {
          console.error(errResp.error);
        }
    )
    
    
    
  }

  getValueToDisplay(params: ICellRendererParams) {
    return params.valueFormatted ? params.valueFormatted : params.value;
  }
  

  async presentCreatedToast(mens, color) {
    const successToast = await this.toastController.create({
      message: mens,
      duration: 5000,
      color: color,
      showCloseButton: true,
      closeButtonText: 'cerrar'
    });
    successToast.present()
  }
}
