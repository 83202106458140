import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders, HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service'

@Injectable()
export class MainInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    private authService: AuthService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> { 
      if (localStorage.getItem('id_token')) {
        req = req.clone({
          url: req.url,
          setHeaders: {
            //'Content-Type':  'application/json',
            //'Accept':  'application/json',
            'Authorization': 'Bearer ' +  localStorage.getItem('id_token')
          }
        })   
      }
      return next.handle(req).pipe(
        map((event: HttpEvent<any>) => {
          return event;
        }),
        catchError(
          (
            errResp: HttpErrorResponse,
            _: Observable<HttpEvent<any>>
          ) => {
            if (errResp.status === 401 || errResp.status === 403) {
              this.authService.logout().then(() => {
                this.router.navigate(['/login']);
              });
            }
            return throwError(errResp);
          }
        )
      );
  }
}