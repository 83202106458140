import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { ICellRendererParams } from 'ag-grid-community';

import { LicenceService } from 'src/app/services/licence.service';
import { ILicence } from 'src/app/interfaces/Ilicence';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';

import { AdminLicenceBlockPage } from '../../../../pages/admin/licence/admin-licence-block/admin-licence-block.page';
import { ModalController, ToastController } from '@ionic/angular'

@Component({
  selector: 'app-button-block-licence',
  templateUrl: './button-block-licence.component.html',
  styleUrls: ['./button-block-licence.component.scss'],
})
export class ButtonBlockLicenceComponent implements OnInit {

  @Output()actualizar=new EventEmitter();

  private cellValue : string;
  loading: Boolean;
  licence: ILicence;
  id:string;

  constructor(private router: Router, 
              private modalCtrl: ModalController,
              private licenceService: LicenceService,
              private toastController: ToastController) 
            {
              this.loading = false;
              this.licence = null;
             }

  ngOnInit() {}

  refresh(params: ICellRendererParams){
    return true;
  }  
  agInit(params: ICellRendererParams): void{
    this.cellValue = this.getValueToDisplay(params);
  }

  buttonClicked(){
    this.id =`${this.cellValue}`;
    this.licenceService.getOneById(this.id).subscribe(
      (resp: HttpResponse<any>) => {
        this.licence = resp.body.licence;
        //setTimeout(() => {
          this.openModal();  
        //}, 100);
        },
        (errResp: HttpErrorResponse) => {
          console.error(errResp.error);
        }
    )
    
    
  }

  getValueToDisplay(params: ICellRendererParams) {
    return params.valueFormatted ? params.valueFormatted : params.value;
  }

  async openModal(){
    const modal = await this.modalCtrl.create({
      component: AdminLicenceBlockPage,
      componentProps: {
          habilitado: this.licence.licenceParameters.disabled,
          client: this.licence.clientAlias,
          instance: this.licence.instance      
      },
      
    });

    modal.onDidDismiss().then((data) => {
      //console.log(data.data.habilitado);
      if(data.data!='sin datos'){
          let estado = data.data.habilitado;
          if(this.licence.licenceParameters.disabled !== estado){
            //console.log('aca actualizo licencia');
            this.loading = true;
            this.licence.licenceParameters.disabled = estado;
            // this.licenceService.update(this.id, {licence:this.licence}).subscribe( resp => {
            //   if(resp.status == 200){
            //     this.presentCreatedToast('Licencia Bloqueada exitosamente','success');        
            //   }
            // });
            this.licenceService.create({licence: this.licence}).subscribe( resp => {
              if(resp.status == 201){
                this.actualizar.emit('si');
                this.licenceService.filter('cambia');
         
                this.presentCreatedToast('Licencia modificada exitosamente','success');        
              }
            });

          }
          else{
            //console.log('avisar que no se modifico ninguna situacion');
            this.presentCreatedToast('No se modificó la situación ','warning');
          }
      }
    });
    
    
    await modal.present();

  }

  async presentCreatedToast(mens, color) {
    const successToast = await this.toastController.create({
      message: mens,
      duration: 5000,
      color: color,
      showCloseButton: true,
      closeButtonText: 'cerrar'
    });
    successToast.present()
  }

}
