import { Component, OnInit } from '@angular/core';
import { IUser } from 'src/app/interfaces/Iuser';
import { AuthService } from 'src/app/services/auth.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.page.html',
  styleUrls: ['./user-profile.page.scss'],
})
export class UserProfilePage implements OnInit {

  user: Observable<IUser>

  constructor(
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.loadCurrentUser()
  }

  loadCurrentUser() {
    this.user = this.authService.currentUser
  }

  onUserChange(user: IUser) {
    this.authService.updateUser(user)
  }
}
