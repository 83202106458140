import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from '../../environments/environment'
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService {

  private host: string
  private base_url: string

  constructor(private http: HttpClient) {
    // this.base_url = environment.API_BASE_URL
    this.host = window.location.href.split('#', 1)[0]
    this.base_url = this.host + environment.API_PATH
  }

  register(payload): Observable<HttpResponse<any>> {
    return this.http.post(this.base_url + '/users/register', payload, { observe: 'response' })
  }

  create(payload): Observable<HttpResponse<any>> {
    return this.http.post(this.base_url + '/users', payload, { observe: 'response' })
  }

  getAll(): Observable<HttpResponse<any>> {
    return this.http.get(this.base_url + '/users', { observe: 'response' })
  }

  update(id, payload): Observable<HttpResponse<any>> {
    return this.http.put(this.base_url + '/users/' + id, payload, { observe: 'response' })
  }

  getOneById(id): Observable<HttpResponse<any>> {
    return this.http.get(this.base_url + '/users/' + id, { observe: 'response' })
  }

  getUserAvatar(id): Observable<Blob> {
    return this.http.get(this.base_url + '/users/' + id + '/avatar', { responseType: 'blob' } )
  }

  updateUserAvatar(id, payload): Observable<HttpResponse<any>> {
    return this.http.put(this.base_url + '/users/' + id + '/avatar', payload, { observe: 'response' })
  }

  delete(id): Observable<HttpResponse<any>> {
    return this.http.delete(this.base_url + '/users/' + id, { observe: 'response' })
  }
}