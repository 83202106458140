import { Component, OnInit } from '@angular/core'
import { Router, NavigationEnd } from '@angular/router'
import { AuthService } from 'src/app/services/auth.service'

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent implements OnInit {

  constructor(
    private router: Router,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.logout()      
  }

  logout() {
    this.authService.logout()
      .then(() => {
        this.router.navigate(['login'])
      })
  }

  ionViewWillEnter(){
    this.ngOnInit();
  }
}
