import { Component, OnInit, Input, ViewChild } from '@angular/core'
import { FormBuilder, Validators } from '@angular/forms'
import { ProductService } from '../../../../services/product.service'
import { ProductTypeService } from '../../../../services/product-type.service'
import { ProductCategoryService } from '../../../../services/product-category'
import { CompanyService } from '../../../../services/company.service'
import { Router } from '@angular/router'
import { IProduct } from 'src/app/interfaces/Iproduct'
import { IProductType } from 'src/app/interfaces/IproductType'
import { IProductCategory } from 'src/app/interfaces/IproductCategory'
import { ICompany } from 'src/app/interfaces/Icompany'
import { ToastController } from '@ionic/angular'

@Component({
  selector: 'app-product-edit-form',
  templateUrl: './product-edit-form.component.html',
  styleUrls: ['./product-edit-form.component.scss'],
})
export class ProductEditFormComponent implements OnInit {

  @ViewChild('inputChip') inputChip //: ElementRef<IonInput>

  @Input() public product_id: String
  
  loading: Boolean
  product: IProduct
  products: Array<IProduct>
  productTypes: Array<IProductType>
  productCategories: Array<IProductCategory>
  companies: Array<ICompany>
  vsProducts: Array<String>

  tags: Array<string>

  alertOptions: any = {
    cssClass: 'wideAlert'
  }

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private productService: ProductService,
    private productTypeService: ProductTypeService,
    private productCategoryService: ProductCategoryService,
    private companyService: CompanyService,
    private toastController: ToastController
  ) {
    this.loading = false
    this.vsProducts = [
      'Data Spa',
      'Data Analytics',
      'Presentation',
      'EPM'
    ]
    this.tags = []
  }

  productForm = this.fb.group({
    title: ['', Validators.required],
    description: ['', Validators.required],
    image: [null],
    product: ['', Validators.required],
    type: [null, Validators.required],
    category: [null, Validators.required],
    company: [null, Validators.required],
    version: ['', Validators.required],
    dependencies: [null],
    price: [null, Validators.compose([Validators.required, Validators.min(0)])],
    tags: [this.tags],
    active: [false, Validators.required],
    file: [null]
  });
  
  bindForm() {    
    this.productService.getProduct(this.product_id)
      .subscribe(resp => {
        this.product = resp.body.product
        this.tags = this.product.tags
        this.productForm.patchValue(this.product)
      })
  }

  onImageSelect(event) {
    if (event.target.files.length > 0) {
      const img = event.target.files[0];
      this.productForm.get('image').setValue(img);
    }
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.productForm.get('file').setValue(file);
    }
  }
  
  ngOnInit() {
    this.loadProducts()
    this.loadProductTypes()
    this.loadProductCategories()
    this.loadCompanies()
    this.bindForm()
  }

  onSubmit() {
    this.loading = true
  
    const newProduct = {
      'product': this.productForm.value
    }

    newProduct.product.file = ''
    
    const formData = new FormData();
    
    formData.append('file', this.productForm.get('file').value)
    formData.append('image', this.productForm.get('image').value)
    formData.append('payload', JSON.stringify(newProduct))

    this.productService.editProduct(this.product_id, formData)
      .subscribe(resp => {
        if (resp.status == 200) {
          this.presentEditedToast()
          this.router.navigate(['/admin/products', this.product_id])
        }
      }, error => {
        this.presentErrorToast()
      })
  }

  async presentEditedToast() {
    const toast = await this.toastController.create({
      message: 'Producto editado exitosamente',
      duration: 2200,
      color: 'tertiary',
      showCloseButton: true,
      closeButtonText: 'cerrar'
    });
    toast.present()
  }

  async presentErrorToast() {
    const toast = await this.toastController.create({
      message: 'Se produjo un error al intentar modificar el producto',
      // duration: 2200,
      color: 'danger',
      showCloseButton: true,
      closeButtonText: 'cerrar'
    });
    toast.present()
  }

  loadProducts() {
    this.productService.getAll()
      .subscribe(resp => {
        this.products = resp.body.products
        // remove self product from dependencies list
      })
  }

  loadProductTypes() {
    this.productTypeService.getAll()
      .subscribe(resp => {
        this.productTypes = resp.body.productTypes
        //this.productForm.patchValue('')
      })
  }

  loadProductCategories() {
    this.productCategoryService.getAll()
      .subscribe(resp => {
        this.productCategories = resp.body.productCategories
      })
  }

  loadCompanies() {
    this.companyService.getAll()
      .subscribe(resp => {
        this.companies = resp.body.companies
      })
  }

  compareCompany(o1: ICompany, o2: ICompany) {
    return o1 && o2 ? o1._id === o2._id : o1 === o2
  }
  compareProductType(o1: IProductType, o2: IProductType) {
    return o1 && o2 ? o1._id === o2._id : o1 === o2
  }
  compareProductCategory(o1: IProductCategory, o2: IProductCategory) {
    return o1 && o2 ? o1._id === o2._id : o1 === o2
  }

  removeChip(id: number): void {
    this.tags.splice(id, 1)
    this.productForm.controls['tags'].setValue(this.tags)
  }

  addChip(chip: string): void {
    if (chip.trim().length > 0) {
      chip = chip.toLowerCase()
      if (!this.tags) {
        this.tags = [];
      }
      this.tags.push(chip)
      this.productForm.controls['tags'].setValue(this.tags)
      
      this.inputChip.getInputElement()
        .then(input => {
          input.value = ''
        })
    }
  }

  compareDependencies(dep1, dep2) {
    return dep1.id == dep2.id
  }

  productImageSrc(id) {
    return "api/v1/products/"+id+"/image";
  }
}
