import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router'
import { UserService } from 'src/app/services/user.service'
import { IUser } from 'src/app/interfaces/Iuser'

@Component({
  selector: 'app-admin-user-show',
  templateUrl: './admin-user-show.page.html',
  styleUrls: ['./admin-user-show.page.scss'],
})
export class AdminUserShowPage implements OnInit {

  loading: Boolean
  user: IUser

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService
  ) {
    this.loading = false
    this.user = null
  }

  ngOnInit() {
    this.router.events
      .subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.refresh()
        }
      })
  }

  refresh() {
    let id = this.route.snapshot.paramMap.get('id')

    this.userService.getOneById(id)
      .subscribe(resp => {
        this.user = resp.body.user
      })
  }
}
