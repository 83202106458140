import { Component, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router'
import { ProductTypeService } from 'src/app/services/product-type.service'
import { IProductType } from 'src/app/interfaces/IproductType'

@Component({
  selector: 'app-admin-product-type-show',
  templateUrl: './admin-product-type-show.page.html',
  styleUrls: ['./admin-product-type-show.page.scss'],
})
export class AdminProductTypeShowPage implements OnInit {

  loading: Boolean
  productType: IProductType

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private productTypeService: ProductTypeService
  ) {
    this.loading = false
    this.productType = null
  }

  ngOnInit() {
    this.router.events
      .subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.refresh()
        }
      })
    
    /*
    this.product = this.route.paramMap.pipe(
      switchMap((params: ParamMap) => {
          return this.productService.getProduct(params.get('id'))
        })
    )
    */
  }

  refresh() {
    let id = this.route.snapshot.paramMap.get('id')

    this.productTypeService.getOneById(id)
      .subscribe(resp => {
        this.productType = resp.body.productType
      })
  }
}
