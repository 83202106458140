import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-account-validation-error',
  templateUrl: './account-validation-error.page.html',
  styleUrls: ['./account-validation-error.page.scss'],
})
export class AccountValidationErrorPage implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
