import { Component, OnInit, ViewChild } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { FormBuilder } from '@angular/forms'
import { ProductCategoryService } from 'src/app/services/product-category'
import { Observable } from 'rxjs'
import { IProductCategory } from 'src/app/interfaces/IproductCategory'
import { ProductCategoryEditFormComponent } from 'src/app/components/forms/product-category/product-category-edit-form/product-category-edit-form.component'

@Component({
  selector: 'app-admin-product-category-edit',
  templateUrl: './admin-product-category-edit.page.html',
  styleUrls: ['./admin-product-category-edit.page.scss'],
})
export class AdminProductCategoryEditPage implements OnInit {

  @ViewChild(ProductCategoryEditFormComponent)
  form: ProductCategoryEditFormComponent
  
  loading: Boolean
  productCategoryId: String

  constructor(
    private route: ActivatedRoute
  ) {
    this.productCategoryId = this.route.snapshot.paramMap.get('id')
  }

  ngOnInit() {
  }
  
  submitForm() {
    this.form.onSubmit()
  }
}
