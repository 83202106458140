import { Component, OnInit } from '@angular/core';
import { IUser } from 'src/app/interfaces/Iuser';

@Component({
  selector: 'app-new',
  templateUrl: './register.page.html',
  styleUrls: ['./register.page.scss'],
})
export class UserRegisterPage implements OnInit {

  success: Boolean
  user: IUser

  constructor() {
    this.success = false
    this.user = null
  }

  ngOnInit() {
  }

  onSuccess(user: IUser) {
    this.success = true
    this.user = user
  }
}
