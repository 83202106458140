import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { IonicModule } from '@ionic/angular'
import { MainRoutingModule } from './main-routing.module'
import { ReactiveFormsModule } from '@angular/forms'

import { MainBasePage } from './main-base/main-base.page'
import { MainHomePage } from './main-home/main-home.page'
import { MainLoginPage } from './main-login/main-login.page'
import { UserRegisterPage } from './user/register/register.page'
import { LoginFormComponent } from 'src/app/components/login-form/login-form.component'
import { UserFormComponent } from 'src/app/components/forms/user/user-form/user-form.component'
import { LogoutComponent } from 'src/app/components/logout/logout.component'
import { MainToolbarComponent } from 'src/app/components/main-toolbar/main-toolbar.component'
import { LoadingModalComponent } from 'src/app/components/modals/loading-modal/loading-modal.component'
import { UserProfilePage } from './user/user-profile/user-profile.page'
import { UserProfileFormComponent } from 'src/app/components/forms/user-profile-form/user-profile-form.component'
import { MarketplaceIndexPage } from './marketplace/marketplace-index/marketplace-index.page'
import { MarketplaceSidebarComponent } from 'src/app/components/marketplace/marketplace-sidebar/marketplace-sidebar.component'
import { MarketplaceHomePage } from './marketplace/marketplace-home/marketplace-home.page'
import { MarketplaceProductShowPage } from './marketplace/marketplace-product-show/marketplace-product-show.page'
import { ForbiddenPage } from './errors/forbidden/forbidden.page'
import { NotFoundPage } from './errors/not-found/not-found.page'
import { TranslateModule } from '@ngx-translate/core'
import { NewProductReviewModalComponent } from 'src/app/components/modals/new-product-review-modal/new-product-review-modal.component'
import { StarRatingModule } from 'ionic4-star-rating'
import { AccountValidationSuccessPage } from './notifications/account-validation-success/account-validation-success.page'
import { AccountValidationErrorPage } from './notifications/account-validation-error/account-validation-error.page'
import { LicencesLoginPage } from './licenses/licences-login/licences-login.page'
import { LicencesHomePage } from './licenses/licences-home/licences-home.page'

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    MainRoutingModule,
    ReactiveFormsModule,
    TranslateModule,
    StarRatingModule
  ],
  declarations: [
    MainBasePage,
    MainHomePage,
    MainToolbarComponent,
    MainLoginPage,
    UserRegisterPage,
    LoginFormComponent,
    LogoutComponent,
    UserFormComponent,
    LoadingModalComponent,
    UserProfilePage,
    UserProfileFormComponent,
    MarketplaceIndexPage,
    MarketplaceSidebarComponent,
    MarketplaceHomePage,
    MarketplaceProductShowPage,
    ForbiddenPage,
    NotFoundPage,
    AccountValidationSuccessPage,
    AccountValidationErrorPage,
    NewProductReviewModalComponent,
    LicencesLoginPage,
    LicencesHomePage
  ],
  entryComponents: [
    LoadingModalComponent,
    NewProductReviewModalComponent
  ],
  exports: [
    MainToolbarComponent
  ],
})
export class MainModule { }
