import { Component, OnInit } from '@angular/core';
import { MenuController } from '@ionic/angular';

@Component({
  selector: 'app-admin-base',
  templateUrl: './admin-base.page.html',
  styleUrls: ['./admin-base.page.scss'],
})
export class AdminBasePage implements OnInit {

  constructor(
    private menuController: MenuController
  ) { }

  ngOnInit() {
  }

  hideMenu() {
    this.menuController.close()
  }

}
