import { Component, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router'
import { CompanyService } from 'src/app/services/company.service'
import { ICompany } from 'src/app/interfaces/Icompany'

@Component({
  selector: 'app-admin-company-show',
  templateUrl: './admin-company-show.page.html',
  styleUrls: ['./admin-company-show.page.scss'],
})
export class AdminCompanyShowPage implements OnInit {

  loading: Boolean
  company: ICompany

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private companyService: CompanyService
  ) {
    this.loading = false
    this.company = null
  }

  ngOnInit() {
    this.router.events
      .subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.refresh()
        }
      })
  }

  refresh() {
    let id = this.route.snapshot.paramMap.get('id')

    this.companyService.getOneById(id)
      .subscribe(resp => {
        this.company = resp.body.company
      })
  }
}
