import { Injectable } from '@angular/core'
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http'
import { Observable } from 'rxjs'
import { IProductType } from '../interfaces/IproductType'
import { environment } from '../../environments/environment'

@Injectable({
  providedIn: 'root',
})
export class ProductTypeService {

  private host: string
  private base_url: string

  constructor(private http: HttpClient) {
    // this.base_url = environment.API_BASE_URL
    this.host = window.location.href.split('#', 1)[0]
    this.base_url = this.host + environment.API_PATH
  }

  create(payload): Observable<HttpResponse<any>> {
    return this.http.post(this.base_url + '/product-types', payload, { observe: 'response' })
  }

  getAll(): Observable<HttpResponse<any>> {
    return this.http.get(this.base_url + '/product-types', { observe: 'response' })
  }

  getOneById(id): Observable<HttpResponse<any>> {
    return this.http.get(this.base_url + '/product-types/' + id, { observe: 'response' })
  }

  edit(id, payload): Observable<HttpResponse<any>> {
    return this.http.put(this.base_url + '/product-types/' + id, payload, { observe: 'response' })
  }
  
  delete(id): Observable<HttpResponse<any>> {
    return this.http.delete(this.base_url + '/product-types/' + id, { observe: 'response' })
  }
}