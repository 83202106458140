import { Component, OnInit, ViewChild } from '@angular/core';
import { LicenceFormComponent } from 'src/app/components/forms/licence/licence-form/licence-form.component';

@Component({
  selector: 'app-admin-licence-new',
  templateUrl: './admin-licence-new.page.html',
  styleUrls: ['./admin-licence-new.page.scss'],
})
export class AdminLicenceNewPage implements OnInit {

  @ViewChild(LicenceFormComponent)
  form: LicenceFormComponent;

  constructor() { }

  ngOnInit() {
  }

  submitForm() {
    this.form.onSubmit()
  }
}
