import { Injectable } from '@angular/core'
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http'
import { Observable } from 'rxjs'
import { IProduct } from '../interfaces/Iproduct'
import { environment } from '../../environments/environment'

@Injectable({
  providedIn: 'root',
})
export class ProductService {

  private host: string
  private base_url: string

  constructor(private http: HttpClient) {
    // this.base_url = environment.API_BASE_URL
    this.host = window.location.href.split('#', 1)[0]
    this.base_url = this.host + environment.API_PATH
  }

  newProduct(payload): Observable<HttpResponse<any>> {
    return this.http.post(this.base_url + '/products', payload, { observe: 'response' })
  }

  getAll(params?): Observable<HttpResponse<any>> {
    let queryParams = params || '' 
    return this.http.get(this.base_url + '/products' + queryParams, { observe: 'response' })
  }

  getProduct(id): Observable<HttpResponse<any>> {
    return this.http.get(this.base_url + '/products/' + id, { observe: 'response' })
  }

  getProductFile(id): Observable<Blob> {
    return this.http.get(this.base_url + '/products/' + id + '/download', { responseType: 'blob' })
  }

  editProduct(id, payload): Observable<HttpResponse<any>> {
    return this.http.put(this.base_url + '/products/' + id, payload, { observe: 'response' })
  }
  
  deleteProduct(id): Observable<HttpResponse<any>> {
    return this.http.delete(this.base_url + '/products/' + id, { observe: 'response' })
  }

  newReview(id, payload): Observable<HttpResponse<any>> {
    return this.http.post(this.base_url + '/products/' + id + '/review', payload, { observe: 'response' })
  }
}