import { Component, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { CompanyEditFormComponent } from 'src/app/components/forms/company/company-edit-form/company-edit-form.component'

@Component({
  selector: 'app-admin-company-edit',
  templateUrl: './admin-company-edit.page.html',
  styleUrls: ['./admin-company-edit.page.scss'],
})
export class AdminCompanyEditPage implements OnInit {

  @ViewChild(CompanyEditFormComponent)
  form: CompanyEditFormComponent
  
  loading: Boolean
  companyId: String

  constructor(
    private route: ActivatedRoute
  ) {
    this.companyId = this.route.snapshot.paramMap.get('id')
  }

  ngOnInit() {
  }
  
  submitForm() {
    this.form.onSubmit()
  }
}
