import { Component, OnInit } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { TranslateService } from '@ngx-translate/core'
import { AuthService } from './services/auth.service';
import { Subscription } from 'rxjs';
import { IUser } from './interfaces/Iuser';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {

  currentUser: IUser
  userSubscription: Subscription

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private translate: TranslateService,
    private authService: AuthService,
  ) {
    this.translate.setDefaultLang('es')

    this.initializeApp()
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    })
  }

  ngOnInit() {
    this.userSubscription = this.authService.currentUser
      .subscribe(user => {
        this.currentUser = user
        this.setLanguage()
      })
  }

  setLanguage() {
    if (this.currentUser && this.currentUser.profile.languaje) {
      this.translate.use(this.currentUser.profile.languaje.toLowerCase())
    }
  }

  ngOnDestroy() {
    this.userSubscription.unsubscribe()
  }
}
