import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormControl} from '@angular/forms';
import { Observable} from 'rxjs';
import { map, startWith} from 'rxjs/operators';
import { AppInstanceService } from 'src/app/services/app-instance.service';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';

 export interface DataInstances{
   _id: string,
   instanceId: string,
   alias: string
 }

@Component({
  selector: 'app-instance-select',
  templateUrl: './instance-select.component.html',
  styleUrls: ['./instance-select.component.scss'],
})
export class InstanceSelectComponent implements OnInit {

  @Input() panelWidth: string = '800px';
  @Output() selInstance = new EventEmitter();

  loading : boolean;
  dataInstances: DataInstances[] = [];
  myControl = new FormControl();
  filteredDataInstances: Observable<DataInstances[]>;
  
  constructor(private appInstanceService: AppInstanceService){
    this.getInstances();

    setTimeout(() => {
      this.filteredDataInstances = this.myControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value))
      );    
    }, 100);
    
  }

  ngOnInit() {}

  private _filter(value: string): DataInstances[] {
    const filterValue = value.toLowerCase();

    return this.dataInstances.filter(option => option.alias.toLowerCase().includes(filterValue));
  }

  getInstances(){
    this.loading = true;
    this.appInstanceService.getAll().subscribe(
      (resp:HttpResponse<any>) => {
        this.dataInstances = resp.body.instances;
        //console.log('DataInstancesFromComponent: ',this.dataInstances);
        this.loading = false;
      },
      (errResp: HttpErrorResponse) => {
        this.loading = false;
        console.error(errResp.error);
      }
    );
  }

  send(idInstance: string){
    //console.log('estoy madando:', idInstance);
    this.selInstance.emit(idInstance);
    
  }

}
