import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';


@Injectable({
  providedIn: 'root'
})
export class PermissionsGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
    {
      let role = next.data.role; // nessesary role to access this route (defined en route definition)

      if (this.authService.currentUserValue.role === role) {
          return true;
      }

      // user doesn't have nessesary role to access this route
      this.router.navigate(['/forbidden'], { queryParams: { returnUrl: state.url } })
      return false;
  } 
}
