import { Component, OnInit, ViewChild, ElementRef } from '@angular/core'
import { FormBuilder, Validators } from '@angular/forms'
import { AuthService } from '../../services/auth.service'
import { Router, ActivatedRoute } from '@angular/router'
import { ToastController, ModalController } from '@ionic/angular'
import { LoadingModalComponent } from '../../components/modals/loading-modal/loading-modal.component'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent implements OnInit {
  
  @ViewChild('passwordInput', {static: true}) passwordInput
  
  loading: Boolean
  returnUrl: String
  error: String
  errorToast: HTMLIonToastElement
  loadingModal: HTMLIonModalElement

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private toastController: ToastController,
    private modalController: ModalController,
    private translate: TranslateService
  ) {
    this.loading = false
    this.error = ''
    this.errorToast = null
  }

  loginForm = this.fb.group({
    email: ['', [
      Validators.required,
      Validators.email
    ]],
    password: ['', Validators.required]
  })
  
  ngOnInit() {
    // reset login status
    this.authService.logout();

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  onSubmit() {
    if (!this.loginForm.valid) {
      return
    }
    this.loading = true
    this.presentLoadingModal()
      .then(() => {
        if (this.errorToast) {
          this.errorToast.dismiss()
        }
        this.authService.login(this.loginForm.value)
          .subscribe(
            resp => {
              this.authService.setSession(resp)
              this.router.navigate([this.returnUrl])
              this.loading = false
              this.loadingModal.dismiss()
            },
            error => {
              this.error = error
              this.presentErrorToast(this.error)
              this.loading = false
              this.loadingModal.dismiss()
            }
          )
      })
  }

  async presentErrorToast(error) {
    let message = ''

    if (error.status == 404) {
      message = 'PAGES.LOGIN.FORM.NOTIFICATIONS.NO_USER_FOUND'
    } else if (error.status == 403) {
      message = 'PAGES.LOGIN.FORM.NOTIFICATIONS.ACCOUNT_NEEDS_CONFIRMATION'
    } else if (error.status == 401) {
      message = 'PAGES.LOGIN.FORM.NOTIFICATIONS.BAD_CREDENTIALS'
    } else {
      message = 'PAGES.LOGIN.FORM.NOTIFICATIONS.ERROR'
    }

    this.translate.get(message)
      .subscribe((translated: string) => {
        message = translated
      })

    let closeButtonText = ''
    this.translate.get('ACTIONS.CLOSE')
      .subscribe((translated: string) => {
        closeButtonText = translated
      })

    this.errorToast = await this.toastController.create({
      message: message,
      // duration: 2200,
      color: 'danger',
      showCloseButton: true,
      closeButtonText: closeButtonText
    })
    this.errorToast.present()
  }

  async presentLoadingModal() {
    this.loadingModal = await this.modalController.create({
      component: LoadingModalComponent,
      backdropDismiss: false,
      keyboardClose: false,
      cssClass: 'loading-modal'
    })
    return await this.loadingModal.present()
  }
}
