import { Component, OnInit, ViewChild } from '@angular/core'
import { UserAbmFormComponent } from 'src/app/components/forms/user/user-abm-form/user-abm-form.component'

@Component({
  selector: 'app-admin-user-new',
  templateUrl: './admin-user-new.page.html',
  styleUrls: ['./admin-user-new.page.scss'],
})
export class AdminUserNewPage implements OnInit {

  @ViewChild(UserAbmFormComponent)
  form: UserAbmFormComponent
  
  constructor(
  ) { }

  ngOnInit() {
  }

  submitForm() {
    this.form.onSubmit()
  }
}
