import { Component, OnInit } from '@angular/core';
import { IProductType } from '../../../../interfaces/IproductType'
import { ProductTypeService } from '../../../../services/product-type.service'
import { Router, NavigationEnd } from '@angular/router';
import { ToastController, AlertController } from '@ionic/angular';

@Component({
  selector: 'app-product-type-list',
  templateUrl: './admin-product-type-list.page.html',
  styleUrls: ['./admin-product-type-list.page.scss'],
})
export class AdminProductTypeListPage implements OnInit {

  loading: Boolean
  productTypes: IProductType[]

  constructor(
    private productTypeService: ProductTypeService,
    private router: Router  ,
    private toastController: ToastController,
    private alertController: AlertController
  ) {
    this.loading = false
  }

  ngOnInit() {
    // this.refresh()
    
    this.router.events
      .subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.refresh()
        }
      })
  }

  refresh() {
    this.loading = true
    
    this.productTypeService.getAll()
    .subscribe(resp => {
      this.productTypes = resp.body.productTypes
      this.loading = false
    })    
  }

  deleteProductType(id) {
    this.productTypeService.delete(id)
      .subscribe(resp => {
        if (resp.status == 200) {
          this.presentDeletedToast()
          this.refresh()
        } else {
          console.log(resp)
        }
      })
  }

  async presentConfirm(productType: IProductType): Promise<any> {
    return new Promise(async (resolve) => {
      const alert = await this.alertController.create({
        header: 'Confirmar',
        message: 'Confirma que deséa eliminar el tipo de producto "'+productType.name+'"?',
        buttons: [
          {
            text: 'Cancelar',
            role: 'cancel',
            cssClass: 'secondary',
            handler: (cancel) => {
              resolve(false);
            }
          }, {
            text: 'Eliminar',
            handler: (ok) => {
              resolve(true);
            }
          }
        ]
      })
      alert.present()
    })
  }

  onDelete(event, productType) {
      this.presentConfirm(productType)
      .then(confirm => {
        if (confirm) {
          this.deleteProductType(productType._id)
        }
      })
  }

  async presentDeletedToast() {
    const toast = await this.toastController.create({
      message: 'Tipo de Producto eliminado correctamente',
      duration: 2500,
      color: 'tertiary',
      showCloseButton: true,
      closeButtonText: 'cerrar'
    });
    toast.present()
  }
}
