import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';


@Component({
  selector: 'app-button-renew-licence',
  templateUrl: './button-renew-licence.component.html',
  styleUrls: ['./button-renew-licence.component.scss'],
})
export class ButtonRenewLicenceComponent implements OnInit {

  private cellValue : string;

  constructor(private router: Router) { }

  ngOnInit() {}

  refresh(params: ICellRendererParams){
    return true;
  }  
  agInit(params: ICellRendererParams): void{
    this.cellValue = this.getValueToDisplay(params);
  }
  buttonClicked(url){
    let id =`${this.cellValue}`;
   
    let newurl=  `${url}/${id}/edit`;
    this.router.navigateByUrl(newurl)
      .then(e=>{
        if(e){
          console.log('ok!');
          
        }else{
          console.log('fail!!');
          
        }
      });
    
  }
  getValueToDisplay(params: ICellRendererParams) {
    return params.valueFormatted ? params.valueFormatted : params.value;
  }
}
