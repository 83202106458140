import { NgModule }             from '@angular/core'
import { RouterModule, Routes } from '@angular/router'

import { MainBasePage } from './main-base/main-base.page'
import { MainHomePage } from './main-home/main-home.page'
import { MainLoginPage } from './main-login/main-login.page'
import { LogoutComponent } from '../../components/logout/logout.component'
import { UserRegisterPage } from './user/register/register.page'
import { UserProfilePage } from './user/user-profile/user-profile.page'
import { MarketplaceIndexPage } from './marketplace/marketplace-index/marketplace-index.page'
import { MarketplaceHomePage } from './marketplace/marketplace-home/marketplace-home.page'
import { MarketplaceProductShowPage } from './marketplace/marketplace-product-show/marketplace-product-show.page'
import { ForbiddenPage } from './errors/forbidden/forbidden.page'
import { AccountValidationSuccessPage } from './notifications/account-validation-success/account-validation-success.page'
import { AccountValidationErrorPage } from './notifications/account-validation-error/account-validation-error.page'
import { LicencesLoginPage } from './licenses/licences-login/licences-login.page'
import { LicencesHomePage } from './licenses/licences-home/licences-home.page'
import { AuthGuard } from 'src/app/guards/auth.guard'
import { PermissionsGuard } from 'src/app/guards/permissions.guard'

const mainRoutes: Routes = [
  {
    path: '',
    component: MainBasePage,
    children: [
      {
        path: '',
        component: MainHomePage
      },
      {
        path: 'login',
        component: MainLoginPage
      },
      {
        path: 'logout',
        component: LogoutComponent
      },
      {
        path: 'register',
        component: UserRegisterPage
      },
      {
        path: 'account-validation-success',
        component: AccountValidationSuccessPage
      },
      {
        path: 'account-validation-error',
        component: AccountValidationErrorPage
      },
      {
        path: 'account',
        component: UserProfilePage
      },
      {
        path: 'marketplace',
        component: MarketplaceIndexPage,
        /*
        children: [
          {
            path: '',
            component: MarketplaceHomePage,
            outlet: 'marketplace'
          }
        ]
        */
      },
      {
        path: 'marketplace/products/:id',
        component: MarketplaceProductShowPage,
      },
      {
        path: 'licences',
        canActivate: [AuthGuard, PermissionsGuard],
        data: {role: 'admin'},
        component: LicencesHomePage
      },
      {
        path: 'licences/login',
        component: LicencesLoginPage,
      },
      {
        path: 'forbidden',
        component: ForbiddenPage
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(mainRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class MainRoutingModule { }