import { Injectable } from '@angular/core'
// import { environment } from '../../environments/environment'

@Injectable({
  providedIn: 'root',
})
export class ImageManager {

  constructor() { }

  createImageFromBlob(image: Blob) {
    return new Promise(resolve => {
      let reader = new FileReader()

      reader.addEventListener("load", () => {
        resolve(reader.result)
      }, false)

      if (image) {
        reader.readAsDataURL(image)
      }
    })
  }
}