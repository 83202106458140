import { Component, OnInit, ViewChild } from '@angular/core';
import { ProductFormComponent } from 'src/app/components/forms/product/product-form/product-form.component';

@Component({
  selector: 'app-admin-product-new',
  templateUrl: './admin-product-new.page.html',
  styleUrls: ['./admin-product-new.page.scss'],
})
export class AdminProductNewPage implements OnInit {

  @ViewChild(ProductFormComponent)
  form: ProductFormComponent
  
  constructor(
  ) { }

  ngOnInit() {
  }

  submitForm() {
    this.form.onSubmit()
  }
}
