import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IProductCategory } from 'src/app/interfaces/IproductCategory';
import { IProductType } from 'src/app/interfaces/IproductType';
import { ICompany } from 'src/app/interfaces/Icompany';

@Component({
  selector: 'app-marketplace-sidebar',
  templateUrl: './marketplace-sidebar.component.html',
  styleUrls: ['./marketplace-sidebar.component.scss'],
})
export class MarketplaceSidebarComponent implements OnInit {

  @Input() productCategories: [IProductCategory]
  @Input() productTypes: [IProductType]
  @Input() companies: [ICompany]
  
  @Output() productsFilter = new EventEmitter<Array<string>>()
  @Output() typesFilter = new EventEmitter<Array<String>>()
  @Output() categoriesFilter = new EventEmitter<Array<String>>()
  @Output() companiesFilter = new EventEmitter<Array<String>>()
  
  selectedProducts: Array<string>
  selectedCompanies: Array<String>
  selectedTypes: Array<String>
  selectedCategories: Array<String>

  vsProducts: Array<string>

  constructor() {
    this.selectedProducts = []
    this.selectedCompanies = []
    this.selectedTypes = []
    this.selectedCategories = []

    this.vsProducts = [
      'Suite',
      'Data Spa',
      'Data Analytics',
      'Presentation',
      'EPM'
    ]
  }

  ngOnInit() {
  }

  toggleProductFilter(event, product: string) {
    if (event.target.checked) {
      this.selectedProducts.push(product)
    } else {
      let index = this.selectedProducts.indexOf(product)
      if (index > -1) {
        this.selectedProducts.splice(index, 1)
      }
    }
    this.productsFilter.emit(this.selectedProducts)
  }

  toggleProductTypeFilter(event, type: IProductType) {
    if (event.target.checked) {
      this.selectedTypes.push(type._id)
    } else {
      let index = this.selectedTypes.indexOf(type._id)
      if (index > -1) {
        this.selectedTypes.splice(index, 1)
      }
    }
    this.typesFilter.emit(this.selectedTypes)
  }

  toggleProductCategoryFilter(event, category: IProductCategory) {
    if (event.target.checked) {
      this.selectedCategories.push(category._id)
    } else {
      let index = this.selectedCategories.indexOf(category._id)
      if (index > -1) {
        this.selectedCategories.splice(index, 1)
      }
    }
    this.categoriesFilter.emit(this.selectedCategories)
  }

  toggleCompanyFilter(event, company: ICompany) {
    if (event.target.checked) {
      this.selectedCompanies.push(company._id)
    } else {
      let index = this.selectedCompanies.indexOf(company._id)
      if (index > -1) {
        this.selectedCompanies.splice(index, 1)
      }
    }
    this.companiesFilter.emit(this.selectedCompanies)
  }
}
