import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ProductTypeService } from '../../../../services/product-type.service'
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';

@Component({
  selector: 'app-product-type-form',
  templateUrl: './product-type-form.component.html',
  styleUrls: ['./product-type-form.component.scss'],
})
export class ProductTypeFormComponent implements OnInit {

  loading: Boolean

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private productTypeService: ProductTypeService,
    private toastController: ToastController
  ) {
    this.loading = false
  }

  productTypeForm = this.fb.group({
    name: ['', Validators.required],
    description: ['', Validators.required]
  })

  ngOnInit() {}

  onSubmit() {
    this.loading = true
  
    const newProductType = {
      'productType': this.productTypeForm.value
    }

    this.productTypeService.create(newProductType)
      .subscribe(resp => {
        if (resp.status == 201) {
          this.presentCreatedToast()
          this.router.navigate(['/admin/product-types', resp.body.productType._id])
        }
      }, error => {
        // default error message
        let message = 'Se produjo un error al crear el nuevo tipo de producto, inténtelo nuevamente.';
        
        // duplicate key
        if (error.status == 409) {
          message = 'El nombre ingresado ya se encuentra registrado.'  
        }
        this.presentErrorToast(message)
      })
  }

  async presentCreatedToast() {
    const successToast = await this.toastController.create({
      message: 'Tipo de Producto creado exitosamente',
      duration: 2200,
      color: 'tertiary',
      showCloseButton: true,
      closeButtonText: 'cerrar'
    });
    successToast.present()
  }

  async presentErrorToast(message: string) {
    const errorToast = await this.toastController.create({
      message: message,
      duration: 0,
      color: 'danger',
      showCloseButton: true,
      closeButtonText: 'cerrar'
    });
    errorToast.present()
  }
}
