import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core'
import { ModalController, ToastController } from '@ionic/angular'
import { Router } from '@angular/router'
import { FormBuilder, Validators } from '@angular/forms'
import { UserService } from 'src/app/services/user.service'
import { IUser } from 'src/app/interfaces/Iuser'
import { LoadingModalComponent } from '../../modals/loading-modal/loading-modal.component'
import { Observable, Subscription } from 'rxjs'
import { ImageManager } from 'src/app/services/image-manager.service'
import { DomSanitizer } from '@angular/platform-browser'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'app-user-profile-form',
  templateUrl: './user-profile-form.component.html',
  styleUrls: ['./user-profile-form.component.scss'],
})
export class UserProfileFormComponent implements OnInit {

  @Input() userObservable: Observable<IUser>
  @Output() userChange = new EventEmitter<IUser>()

  @ViewChild("avatarInput") avatarInput
  
  loading: Boolean
  loadingModal: HTMLIonModalElement
  user: IUser
  userSubscription: Subscription
  isImageLoading: Boolean
  avatarSrc: any
  languages: Array<object>

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private userService: UserService,
    private toastController: ToastController,
    private modalController: ModalController,
    private imageManager: ImageManager,
    private sanitizer: DomSanitizer,
    private translate: TranslateService
  ) {
    this.loading = false
    this.isImageLoading = false
    this.languages = [
      {
        name: 'LANGUAGES.SPANISH',
        iso_code: 'es'
      },
      {
        name: 'LANGUAGES.ENGLISH',
        iso_code: 'en'
      }
    ]
  }

  userProfileForm = this.fb.group({
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    email: [{value: '', disabled: true}, [Validators.required, Validators.email]],
    profile: this.fb.group({
      fullName: [''],
      position: [''],
      department: [''],
      organization: [''],
      address: [''],
      avatar: [''],
      languaje: [{value: '', disabled: false}, [Validators.required]]
    })
  })

  ngOnInit() {
    this.userSubscription = this.userObservable.subscribe(
      newValue => {
        this.user = newValue
        this.bindForm()
        this.setAvatarSrc()
      }
    )
  }

  bindForm() {    
    this.userProfileForm.patchValue(this.user)    
  }

  onSubmit() {
    this.loading = true
    this.presentLoadingModal()
      .then(() => {
        
        const payload = {
          'user': this.userProfileForm.value
        }
        
        this.userService.update(this.user._id, payload)
          .subscribe(
            resp => {
              this.loading = false
              this.loadingModal.dismiss()
              if (resp.status == 200) {
                this.onSuccess(resp.body.user)
              }
            },
            err => {
              this.loading = false
              this.loadingModal.dismiss()
              console.log(err)
            }
          )
      })
  }

  onSuccess(editedUser: IUser) {
    this.presentUpdatedToast()
    this.userChange.emit(editedUser)
  }

  async presentUpdatedToast() {
    
    let message = ''
    this.translate.get('PAGES.USER_ACCOUNT.PROFILE.FORM.NOTIFICATIONS.SUCCESS')
      .subscribe((translated: string) => {
        message = translated
      })

    let closeButtonText = ''
    this.translate.get('ACTIONS.CLOSE')
      .subscribe((translated: string) => {
        closeButtonText = translated
      })

    const successToast = await this.toastController.create({
      message: message,
      duration: 2200,
      color: 'tertiary',
      showCloseButton: true,
      closeButtonText: closeButtonText
    })
    successToast.present()
  }

  async presentLoadingModal() {
    this.loadingModal = await this.modalController.create({
      component: LoadingModalComponent,
      backdropDismiss: false,
      keyboardClose: false,
      cssClass: 'loading-modal'
    })
    return await this.loadingModal.present()
  }

  uploadAvatar() {
    if (!this.isImageLoading) {
      this.avatarInput.getInputElement()
        .then(input => {
          input.click()
        })
    }
  }

  onFileSelect(event) {
    this.isImageLoading = true

    if (event.target.files.length > 0) {

      const formData = new FormData();
      formData.append('avatar', event.target.files[0])
      
      this.userService.updateUserAvatar(this.user._id, formData)
        .subscribe(
          resp => {
            this.userChange.emit(resp.body.user)
            this.isImageLoading = false
          },
          err => {
            console.log(err)
            this.isImageLoading = false
          }
        )
    }
  }

  setAvatarSrc() {
    this.isImageLoading = true
    this.userService.getUserAvatar(this.user._id)
      .subscribe(resp => {
        this.imageManager.createImageFromBlob(resp)
        .then(img => {
          this.avatarSrc = this.sanitizer.bypassSecurityTrustUrl('' + img)
          this.isImageLoading = false
        })
      }, error => {
        this.avatarSrc = null
        this.isImageLoading = false
        
        if (error.status != 404) {
          console.log(error)
        }
      })
  }

  compareLanguaje(o1, o2) {
    return o1 == o2
  }

  ngOnDestroy() {
    this.userSubscription.unsubscribe()
  }
}
