import { Component, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { UserAbmEditFormComponent } from 'src/app/components/forms/user/user-abm-edit-form/user-abm-edit-form.component'

@Component({
  selector: 'app-admin-user-edit',
  templateUrl: './admin-user-edit.page.html',
  styleUrls: ['./admin-user-edit.page.scss'],
})
export class AdminUserEditPage implements OnInit {

  @ViewChild(UserAbmEditFormComponent)
  form: UserAbmEditFormComponent
  
  loading: Boolean
  userId: String

  constructor(
    private route: ActivatedRoute
  ) {
    this.userId = this.route.snapshot.paramMap.get('id')
  }

  ngOnInit() {
  }
  
  submitForm() {
    this.form.onSubmit()
  }
}
