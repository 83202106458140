import { Component, OnInit } from '@angular/core';
import { ILicence } from 'src/app/interfaces/Ilicence';
import { LicenceService } from 'src/app/services/licence.service';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-admin-licence-show',
  templateUrl: './admin-licence-show.page.html',
  styleUrls: ['./admin-licence-show.page.scss'],
})
export class AdminLicenceShowPage implements OnInit {

  loading: Boolean;
  licence: any;
  historyVersion: any[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private licenceService: LicenceService
  ) {
    this.loading = false;
    this.licence = null;
  }

  ngOnInit() {
    this.router.events
      .subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.refresh()
        }
      })
  }

  refresh() {
    let id = this.route.snapshot.paramMap.get('id')

    this.licenceService.getOneById(id).subscribe(
      (resp: HttpResponse<any>) => {
        this.licence = resp.body.licence;
        if (this.licence.appInstance.listVersion){
          this.historyVersion = this.licence.appInstance.listVersion.sort((a,b)=>{
            const x = a.updated;
            const y = b.updated;
            return ((x<y)?1:((x>y)?-1:0));
          });
        }
        

      },
      (errResp: HttpErrorResponse) => {
        console.error(errResp.error);
      }
    )
  }
}
