import { Component, OnInit, ViewChild } from '@angular/core';
import { ProductCategoryFormComponent } from 'src/app/components/forms/product-category/product-category-form/product-category-form.component';

@Component({
  selector: 'app-admin-product-category-new',
  templateUrl: './admin-product-category-new.page.html',
  styleUrls: ['./admin-product-category-new.page.scss'],
})
export class AdminProductCategoryNewPage implements OnInit {

  @ViewChild(ProductCategoryFormComponent)
  form: ProductCategoryFormComponent
  
  constructor(
  ) { }

  ngOnInit() {
  }

  submitForm() {
    this.form.onSubmit()
  }
}
