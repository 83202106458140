import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http'
import { Observable } from 'rxjs'
import { environment } from '../../environments/environment'


@Injectable({
  providedIn: 'root'
})
export class AppInstanceService {
  private host: string;
  private base_url: string;

  constructor(private http:HttpClient) {
    this.host = window.location.href.split('#', 1)[0]
    this.base_url = this.host + environment.API_PATH
   }

   getAll(): Observable<HttpResponse<any>> {
    //console.log(this.base_url);
    
    return this.http.get(this.base_url + '/app-instances', { observe: 'response' })
  }

  getList(): Observable<HttpResponse<any>> {
    //console.log(this.base_url);
    
    return this.http.get(this.base_url + '/app-instances/list', { observe: 'response' })
  }

}
