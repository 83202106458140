import { Component, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router'
import { ProductService } from 'src/app/services/product.service'
import { IProduct } from 'src/app/interfaces/Iproduct'

@Component({
  selector: 'app-admin-product-show',
  templateUrl: './admin-product-show.page.html',
  styleUrls: ['./admin-product-show.page.scss'],
})
export class AdminProductShowPage implements OnInit {

  loading: Boolean
  product: IProduct

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private productService: ProductService
  ) {
    this.loading = false
    this.product = null
  }

  ngOnInit() {
    this.router.events
      .subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.refresh()
        }
      })
  }

  refresh() {
    let id = this.route.snapshot.paramMap.get('id')

    this.productService.getProduct(id)
      .subscribe(resp => {
        this.product = resp.body.product
      })
  }

  productImageSrc(id) {
    return "api/v1/products/"+id+"/image";
  }
}
