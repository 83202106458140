import { Component, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router'
import { ProductCategoryService } from 'src/app/services/product-category'
import { IProductCategory } from 'src/app/interfaces/IproductCategory'

@Component({
  selector: 'app-admin-product-category-show',
  templateUrl: './admin-product-category-show.page.html',
  styleUrls: ['./admin-product-category-show.page.scss'],
})
export class AdminProductCategoryShowPage implements OnInit {

  loading: Boolean
  productCategory: IProductCategory

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private productCategoryService: ProductCategoryService
  ) {
    this.loading = false
    this.productCategory = null
  }

  ngOnInit() {
    this.router.events
      .subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.refresh()
        }
      })
  }

  refresh() {
    let id = this.route.snapshot.paramMap.get('id')

    this.productCategoryService.getOneById(id)
      .subscribe(resp => {
        this.productCategory = resp.body.productCategory
      })
  }
}
