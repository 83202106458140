import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from '../../environments/environment'
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LicenceService {

  private host: string
  private base_url: string

  constructor(private http: HttpClient) {
    // this.base_url = environment.API_BASE_URL
    this.host = window.location.href.split('#', 1)[0]
    this.base_url = this.host + environment.API_PATH
  }

  delete(id): Observable<HttpResponse<any>> {
    return this.http.delete(this.base_url + '/licences/' + id, { observe: 'response' })
  }

  create(payload): Observable<HttpResponse<any>> {
    return this.http.post(this.base_url + '/licences', payload, { observe: 'response' })
  }

  getAll(): Observable<HttpResponse<any>> {
    return this.http.get(this.base_url + '/licences', { observe: 'response' })
  }

  getOneById(id): Observable<HttpResponse<any>> {
    return this.http.get(this.base_url + '/licences/' + id, { observe: 'response' })
  }

  update(id, payload): Observable<HttpResponse<any>>{
    return this.http.put(this.base_url + '/licences/' + id, payload, { observe: 'response'})
  }

  private _listener = new Subject<any>();
  listen():Observable<any>{
    return this._listener.asObservable();
  }

  filter(filterBy: string){
    this._listener.next(filterBy);
  }

}