import { Component, OnInit } from '@angular/core';
import { ILicence } from 'src/app/interfaces/Ilicence';
import { LicenceService } from 'src/app/services/licence.service';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { ModalController, ToastController } from '@ionic/angular'
import { LoginFormComponent } from 'src/app/components/login-form/login-form.component';


@Component({
  selector: 'app-admin-licence-renew',
  templateUrl: './admin-licence-renew.page.html',
  styleUrls: ['./admin-licence-renew.page.scss'],
})
export class AdminLicenceRenewPage implements OnInit {

  loading: Boolean;
  public licence: ILicence;
  licenceDateFrom: string;
  licenceDateTo: string;
  public renewLicence : ILicence;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private toastController: ToastController,
    private licenceService: LicenceService
  ) {
    this.loading = false;
    this.licence = null;
  }

  ngOnInit() {
    const now: Date = new Date();
    const fromDate: Date = new Date(now.getFullYear(), now.getMonth() - 1);
    const toDate: Date = new Date(now.getFullYear() + 5, 11, 31);
    this.licenceDateFrom = fromDate.toISOString();
    this.licenceDateTo = toDate.toISOString();
    
    this.router.events
      .subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.refresh()
        }
      })
  }

  refresh() {
    let id = this.route.snapshot.paramMap.get('id')
    this.licenceService.getOneById(id).subscribe(
      (resp: HttpResponse<any>) => {
        this.licence = resp.body.licence;
      },
      (errResp: HttpErrorResponse) => {
        console.error(errResp.error);
      }
    )
  }

  save(){
    let client : string = this.licence?.clientAlias;
    let inst : string = this.licence?.instance;
    let parametros : any = this.licence?.licenceParameters;
    const renueva = {
      clientAlias: client,
      instance: inst,
      licenceParameters: parametros
    }
    this.loading = true;
    const payload = {'licence': renueva};
    this.licenceService.create(payload).subscribe( resp =>{
      if(resp.status == 201){
        this.presentCreatedToast();        
      }

    }); 
    
  }
  async presentCreatedToast() {
    const successToast = await this.toastController.create({
      message: 'Licencia Renovada exitosamente',
      duration: 5000,
      color: 'success',
      showCloseButton: true,
      closeButtonText: 'cerrar'
    });
    successToast.present()
  }
}
