import { Component, OnInit, Input } from '@angular/core'
import { FormBuilder, Validators } from '@angular/forms'
import { UserService } from '../../../../services/user.service'
import { Router } from '@angular/router'
import { ModalController, ToastController } from '@ionic/angular'
import { LoadingModalComponent } from 'src/app/components/modals/loading-modal/loading-modal.component'
import { IUser } from 'src/app/interfaces/Iuser'

@Component({
  selector: 'app-user-abm-edit-form',
  templateUrl: './user-abm-edit-form.component.html',
  styleUrls: ['./user-abm-edit-form.component.scss'],
})
export class UserAbmEditFormComponent implements OnInit {
  
  @Input() public userId: String
  
  loading: Boolean
  user: IUser
  roles: Array<string>
  loadingModal: HTMLIonModalElement

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private userService: UserService,
    private modalController: ModalController,
    private toastController: ToastController,
  ) {
      this.loading = false
      this.roles = [
        'user',
        'admin'
      ]
    }

  userForm = this.fb.group({
    firstName: [{value: '', disabled: true}, Validators.required],
    lastName: [{value: '', disabled: true}, Validators.required],
    email: [{value: ''}, [
      Validators.required,
      Validators.email
    ]],
    role: [null, Validators.required],
    enabled: [{value: true, disabled: true}, Validators.required],
    blocked: [false, Validators.required],
    profile: this.fb.group({
      fullName: [{value: '', disabled: true}],
      position: [''],
      department: [''],
      organization: [''],
      address: [{value: '', disabled: true}],
      avatar: [''],
      languaje: [{value: '', disabled: true}]
    })
  })
  
  ngOnInit() {
    this.bindForm()
  }

  bindForm() {    
    this.userService.getOneById(this.userId)
      .subscribe(resp => {
        this.user = resp.body.user
        this.userForm.patchValue(this.user)
      })
  }

  onSubmit() {
    this.loading = true
    this.presentLoadingModal()
      .then(() => {
        
        const payload = {
          'user': this.userForm.value
        }
        
        this.userService.update(this.user._id, payload)
          .subscribe(
            resp => {
              this.loading = false
              this.loadingModal.dismiss()
              if (resp.status == 200) {
                this.presentUpdatedToast()
                this.router.navigate(['/admin/users', resp.body.user._id])
              }
            },
            err => {
              this.loading = false
              this.loadingModal.dismiss()
              console.log(err)
            }
          )
      })
  }

  compareRole(role1: String, role2: String) {
    return role1 == role2
  }

  async presentUpdatedToast() {
    const successToast = await this.toastController.create({
      message: 'Usuario actualizado correctamente',
      duration: 2200,
      color: 'tertiary',
      showCloseButton: true,
      closeButtonText: 'cerrar'
    })
    successToast.present()
  }

  async presentLoadingModal() {
    this.loadingModal = await this.modalController.create({
      component: LoadingModalComponent,
      backdropDismiss: false,
      keyboardClose: false,
      cssClass: 'loading-modal'
    })
    return await this.loadingModal.present()
  }
}
