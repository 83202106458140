import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ProductCategoryService } from '../../../../services/product-category'
import { Router } from '@angular/router';
import { IProductCategory } from 'src/app/interfaces/IproductCategory';
import { ToastController } from '@ionic/angular';

@Component({
  selector: 'app-product-category-edit-form',
  templateUrl: './product-category-edit-form.component.html',
  styleUrls: ['./product-category-edit-form.component.scss'],
})
export class ProductCategoryEditFormComponent implements OnInit {

  @Input() public productCategoryId: String
  loading: Boolean
  productCategory: IProductCategory

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private productCategoryService: ProductCategoryService,
    private toastController: ToastController
  ) {
    this.loading = false
  }

  productCategoryForm = this.fb.group({
    name: ['', Validators.required]
  });
  
  bindForm() {    
    this.productCategoryService.getOneById(this.productCategoryId)
      .subscribe(resp => {
        this.productCategory = resp.body.productCategory
        this.productCategoryForm.patchValue(this.productCategory)
      })
  }

  ngOnInit() {
    this.bindForm()
  }

  onSubmit() {
    this.loading = true
  
    const editedProductCategory = {
      'productCategory': this.productCategoryForm.value
    }

    this.productCategoryService.edit(this.productCategoryId, editedProductCategory)
      .subscribe(resp => {
        if (resp.status == 200) {
          this.presentEditedToast()
          this.router.navigate(['/admin/product-categories', this.productCategoryId])
        }
      }, error => {
        // default error message
        let message = 'Se produjo un error al intentar modificar la categoría de producto';
        
        // duplicate key
        if (error.status == 409) {
          message = 'El nombre ingresado ya se encuentra registrado.'  
        }
        this.presentErrorToast(message)
      })
  }

  async presentEditedToast() {
    const toast = await this.toastController.create({
      message: 'Categoría de Producto editada exitosamente',
      duration: 2200,
      color: 'tertiary',
      showCloseButton: true,
      closeButtonText: 'cerrar'
    });
    toast.present()
  }

  async presentErrorToast(message: string) {
    const toast = await this.toastController.create({
      message: message,
      // duration: 2200,
      color: 'danger',
      showCloseButton: true,
      closeButtonText: 'cerrar'
    });
    toast.present()
  }
}
