import { Component, OnInit, Input } from '@angular/core'
import { AuthService } from 'src/app/services/auth.service'
import { UserService } from 'src/app/services/user.service'
import { IUser } from 'src/app/interfaces/Iuser'
import { ImageManager } from 'src/app/services/image-manager.service'
import { DomSanitizer } from '@angular/platform-browser'
import { PopoverController } from '@ionic/angular'
import { UserDropdownMenuComponent } from '../user-dropdown-menu/user-dropdown-menu.component'
import { Subscription } from 'rxjs'

@Component({
  selector: 'app-main-toolbar',
  templateUrl: './main-toolbar.component.html',
  styleUrls: ['./main-toolbar.component.scss'],
})
export class MainToolbarComponent implements OnInit {

  currentUser: IUser
  isImageLoading: Boolean
  avatarSrc: any
  currentPopover: any
  userSubscription: Subscription

  @Input() pageTitle: string

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private imageManager: ImageManager,
    private sanitizer: DomSanitizer,
    public popoverController: PopoverController
  ) {
    this.isImageLoading = false
  }

  ngOnInit() {
    this.userSubscription = this.authService.currentUser
      .subscribe(user => {
        this.currentUser = user
        this.setAvatarSrc()
      })
  }

  setAvatarSrc() {
    if(this.currentUser) {
      this.isImageLoading = true
      this.userService.getUserAvatar(this.currentUser._id)
        .subscribe(
          resp => {
            this.imageManager.createImageFromBlob(resp)
            .then(img => {
              this.avatarSrc = this.sanitizer.bypassSecurityTrustUrl('' + img)
            })
            this.isImageLoading = false  
          },
          error => {
            this.avatarSrc = null
            this.isImageLoading = false
            
            if (error.status != 404) {
              console.log(error)
            }
          })
    }
  }

  async presentPopover(ev: any) {
    this.currentPopover = await this.popoverController.create({
      component: UserDropdownMenuComponent,
      event: ev,
      showBackdrop: false,
      componentProps: {
        user: this.currentUser,
        popover: this.currentPopover
      }
    })
    return await this.currentPopover.present()
  }

  dismissPopover() {
    if (this.currentPopover) {
      this.currentPopover.dismiss().then(() => { this.currentPopover = null })
    }
  }

  ngOnDestroy() {
    this.userSubscription.unsubscribe()
  }
}
