import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ProductTypeService } from '../../../../services/product-type.service'
import { Router } from '@angular/router';
import { IProductType } from 'src/app/interfaces/IproductType';
import { ToastController } from '@ionic/angular';

@Component({
  selector: 'app-product-type-edit-form',
  templateUrl: './product-type-edit-form.component.html',
  styleUrls: ['./product-type-edit-form.component.scss'],
})
export class ProductTypeEditFormComponent implements OnInit {

  @Input() public productTypeId: String
  loading: Boolean
  productType: IProductType

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private productTypeService: ProductTypeService,
    private toastController: ToastController
  ) {
    this.loading = false
  }

  productTypeForm = this.fb.group({
    name: ['', Validators.required],
    description: ['', Validators.required]
  });
  
  bindForm() {    
    this.productTypeService.getOneById(this.productTypeId)
      .subscribe(resp => {
        this.productType = resp.body.productType
        this.productTypeForm.patchValue(this.productType)
      })
  }

  ngOnInit() {
    this.bindForm()
  }

  onSubmit() {
    this.loading = true
  
    const editedProductType = {
      'productType': this.productTypeForm.value
    }

    this.productTypeService.edit(this.productTypeId, editedProductType)
      .subscribe(resp => {
        if (resp.status == 200) {
          this.presentEditedToast()
          this.router.navigate(['/admin/product-types', this.productTypeId])
        }
      }, error => {
        // default error message
        let message = 'Se produjo un error al intentar modificar el tipo de producto. Inténtelo nuevamente.';
        
        // duplicate key
        if (error.status == 409) {
          message = 'El nombre ingresado ya se encuentra registrado.'  
        }
        this.presentErrorToast(message)
      })
  }

  async presentEditedToast() {
    const toast = await this.toastController.create({
      message: 'Tipo de Producto editado exitosamente',
      duration: 2200,
      color: 'tertiary',
      showCloseButton: true,
      closeButtonText: 'cerrar'
    });
    toast.present()
  }

  async presentErrorToast(message: string) {
    const toast = await this.toastController.create({
      message: message,
      // duration: 2200,
      color: 'danger',
      showCloseButton: true,
      closeButtonText: 'cerrar'
    });
    toast.present()
  }
}
