import { Injectable } from '@angular/core'
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http'
import { Observable } from 'rxjs'
import { ICompany } from '../interfaces/Icompany'
import { environment } from '../../environments/environment'

@Injectable({
  providedIn: 'root',
})
export class CompanyService {

  private host: string
  private base_url: string

  constructor(private http: HttpClient) {
    // this.base_url = environment.API_BASE_URL
    this.host = window.location.href.split('#', 1)[0]
    this.base_url = this.host + environment.API_PATH
  }

  create(payload): Observable<HttpResponse<any>> {
    return this.http.post(this.base_url + '/companies', payload, { observe: 'response' })
  }

  getAll(): Observable<HttpResponse<any>> {
    return this.http.get(this.base_url + '/companies', { observe: 'response' })
  }

  getOneById(id): Observable<HttpResponse<any>> {
    return this.http.get(this.base_url + '/companies/' + id, { observe: 'response' })
  }

  edit(id, payload): Observable<HttpResponse<any>> {
    return this.http.put(this.base_url + '/companies/' + id, payload, { observe: 'response' })
  }
  
  delete(id): Observable<HttpResponse<any>> {
    return this.http.delete(this.base_url + '/companies/' + id, { observe: 'response' })
  }
}