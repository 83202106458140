import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-licences-login',
  templateUrl: './licences-login.page.html',
  styleUrls: ['./licences-login.page.scss'],
})
export class LicencesLoginPage implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
