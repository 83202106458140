import { Component, OnInit } from '@angular/core';
import { IUser } from 'src/app/interfaces/Iuser';
import { UserService } from 'src/app/services/user.service'
import { Router, NavigationEnd } from '@angular/router';
import { ToastController, AlertController } from '@ionic/angular';

@Component({
  selector: 'app-user-list',
  templateUrl: './admin-user-list.page.html',
  styleUrls: ['./admin-user-list.page.scss'],
})
export class AdminUserListPage implements OnInit {

  loading: Boolean
  users: IUser[]

  constructor(
    private userService: UserService,
    private router: Router  ,
    private toastController: ToastController,
    private alertController: AlertController
  ) {
    this.loading = false
  }

  ngOnInit() {
    // this.refresh()
    
    this.router.events
      .subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.refresh()
        }
      })
  }

  refresh() {
    this.loading = true
    
    this.userService.getAll()
    .subscribe(resp => {
      this.users = resp.body.users
      this.loading = false
    })    
  }

  deleteUser(id) {
    this.userService.delete(id)
      .subscribe(resp => {
        if (resp.status == 200) {
          this.presentDeletedToast()
          this.refresh()
        } else {
          console.log(resp)
        }
      })
  }

  async presentConfirm(user: IUser): Promise<any> {
    return new Promise(async (resolve) => {
      const alert = await this.alertController.create({
        header: 'Confirmar',
        message: 'Confirma que deséa eliminar el usuario "'+user.email+'"?',
        buttons: [
          {
            text: 'Cancelar',
            role: 'cancel',
            cssClass: 'secondary',
            handler: (cancel) => {
              resolve(false);
            }
          }, {
            text: 'Eliminar',
            handler: (ok) => {
              resolve(true);
            }
          }
        ]
      })
      alert.present()
    })
  }

  onDelete(event, user) {
      this.presentConfirm(user)
      .then(confirm => {
        if (confirm) {
          this.deleteUser(user._id)
        }
      })
  }

  async presentDeletedToast() {
    const toast = await this.toastController.create({
      message: 'Organización/Desarrollador eliminada correctamente',
      duration: 2500,
      color: 'tertiary',
      showCloseButton: true,
      closeButtonText: 'cerrar'
    });
    toast.present()
  }
}
