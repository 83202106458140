import { NgModule }             from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AdminBasePage } from './admin-base/admin-base.page'
import { AdminHomePage } from './admin-home/admin-home.page';
import { ProductListPage } from './product/product-list/product-list.page';
import { AuthGuard } from '../../guards/auth.guard';
import { AdminProductNewPage } from './product/admin-product-new/admin-product-new.page';
import { AdminProductShowPage } from './product/admin-product-show/admin-product-show.page';
import { AdminProductEditPage } from './product/admin-product-edit/admin-product-edit.page';
import { AdminProductTypeListPage } from './product-type/admin-product-type-list/admin-product-type-list.page'
import { AdminProductTypeEditPage } from './product-type/admin-product-type-edit/admin-product-type-edit.page';
import { AdminProductTypeNewPage } from './product-type/admin-product-type-new/admin-product-type-new.page';
import { AdminProductTypeShowPage } from './product-type/admin-product-type-show/admin-product-type-show.page';
import { AdminProductCategoryListPage } from './product-category/admin-product-category-list/admin-product-category-list.page';
import { AdminProductCategoryNewPage } from './product-category/admin-product-category-new/admin-product-category-new.page';
import { AdminProductCategoryShowPage } from './product-category/admin-product-category-show/admin-product-category-show.page';
import { AdminProductCategoryEditPage } from './product-category/admin-product-category-edit/admin-product-category-edit.page';
import { AdminCompanyListPage } from './company/admin-company-list/admin-company-list.page';
import { AdminCompanyShowPage } from './company/admin-company-show/admin-company-show.page';
import { AdminCompanyEditPage } from './company/admin-company-edit/admin-company-edit.page';
import { AdminCompanyNewPage } from './company/admin-company-new/admin-company-new.page';
import { PermissionsGuard } from 'src/app/guards/permissions.guard';
import { AdminUserListPage } from './user/admin-user-list/admin-user-list.page';
import { AdminUserEditPage } from './user/admin-user-edit/admin-user-edit.page';
import { AdminUserShowPage } from './user/admin-user-show/admin-user-show.page';
import { AdminUserNewPage } from './user/admin-user-new/admin-user-new.page';
import { AdminLicenceListPage } from './licence/admin-licence-list/admin-licence-list.page';
import { AdminLicenceNewPage } from './licence/admin-licence-new/admin-licence-new.page';
import { AdminLicenceShowPage } from './licence/admin-licence-show/admin-licence-show.page';
import { AdminLicenceRenewPage } from './licence/admin-licence-renew/admin-licence-renew.page';

const adminRoutes: Routes = [
  {
    path: 'admin',
    component: AdminBasePage,
    canActivate: [AuthGuard, PermissionsGuard],
    data: {role: 'admin'},
    children: [
      {
        path: '',
        component: AdminHomePage
      },
      {
        path: 'users',
        component: AdminUserListPage
      },
      {
        path: 'users/new',
        component: AdminUserNewPage
      },
      {
        path: 'users/:id',
        component: AdminUserShowPage
      },
      {
        path: 'users/:id/edit',
        component: AdminUserEditPage
      },
      {
        path: 'products',
        component: ProductListPage
      },
      {
        path: 'products/new',
        component: AdminProductNewPage
      },
      {
        path: 'products/:id',
        component: AdminProductShowPage
      },
      {
        path: 'products/:id/edit',
        component: AdminProductEditPage
      },
      {
        path: 'product-types',
        component: AdminProductTypeListPage
      },
      {
        path: 'product-types/new',
        component: AdminProductTypeNewPage
      },
      {
        path: 'product-types/:id',
        component: AdminProductTypeShowPage
      },
      {
        path: 'product-types/:id/edit',
        component: AdminProductTypeEditPage
      },
      {
        path: 'product-categories',
        component: AdminProductCategoryListPage
      },
      {
        path: 'product-categories/new',
        component: AdminProductCategoryNewPage
      },
      {
        path: 'product-categories/:id',
        component: AdminProductCategoryShowPage
      },
      {
        path: 'product-categories/:id/edit',
        component: AdminProductCategoryEditPage
      },
      {
        path: 'companies',
        component: AdminCompanyListPage
      },
      {
        path: 'companies/new',
        component: AdminCompanyNewPage
      },
      {
        path: 'companies/:id',
        component: AdminCompanyShowPage
      },
      {
        path: 'companies/:id/edit',
        component: AdminCompanyEditPage
      },
      ///////////////////////////////
      // Licence
      {
        path: 'licences',
        component: AdminLicenceListPage
      },
      {
        path: 'licences/new',
        component: AdminLicenceNewPage
      },
      {
        path: 'licences/:id',
        component: AdminLicenceShowPage
      },
      {
        path: 'licences/:id/edit',
        component: AdminLicenceRenewPage
      },
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(adminRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class AdminRoutingModule { }