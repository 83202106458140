import { Component, OnInit } from '@angular/core';
import { IProductCategory } from 'src/app/interfaces/IproductCategory';
import { ProductCategoryService } from '../../../../services/product-category'
import { Router, NavigationEnd } from '@angular/router';
import { ToastController, AlertController } from '@ionic/angular';

@Component({
  selector: 'app-product-category-list',
  templateUrl: './admin-product-category-list.page.html',
  styleUrls: ['./admin-product-category-list.page.scss'],
})
export class AdminProductCategoryListPage implements OnInit {

  loading: Boolean
  productCategories: IProductCategory[]

  constructor(
    private productCategoryService: ProductCategoryService,
    private router: Router  ,
    private toastController: ToastController,
    private alertController: AlertController
  ) {
    this.loading = false
  }

  ngOnInit() {
    // this.refresh()
    
    this.router.events
      .subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.refresh()
        }
      })
  }

  refresh() {
    this.loading = true
    
    this.productCategoryService.getAll()
    .subscribe(resp => {
      this.productCategories = resp.body.productCategories
      this.loading = false
    })    
  }

  deleteProductCategory(id) {
    this.productCategoryService.delete(id)
      .subscribe(resp => {
        if (resp.status == 200) {
          this.presentDeletedToast()
          this.refresh()
        } else {
          console.log(resp)
        }
      })
  }

  async presentConfirm(productCategory: IProductCategory): Promise<any> {
    return new Promise(async (resolve) => {
      const alert = await this.alertController.create({
        header: 'Confirmar',
        message: 'Confirma que deséa eliminar la categoría de producto "'+productCategory.name+'"?',
        buttons: [
          {
            text: 'Cancelar',
            role: 'cancel',
            cssClass: 'secondary',
            handler: (cancel) => {
              resolve(false);
            }
          }, {
            text: 'Eliminar',
            handler: (ok) => {
              resolve(true);
            }
          }
        ]
      })
      alert.present()
    })
  }

  onDelete(event, productCategory) {
      this.presentConfirm(productCategory)
      .then(confirm => {
        if (confirm) {
          this.deleteProductCategory(productCategory._id)
        }
      })
  }

  async presentDeletedToast() {
    const toast = await this.toastController.create({
      message: 'Categoría de Producto eliminada correctamente',
      duration: 2500,
      color: 'tertiary',
      showCloseButton: true,
      closeButtonText: 'cerrar'
    });
    toast.present()
  }
}
