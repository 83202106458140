import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { CompanyService } from '../../../../services/company.service'
import { Router } from '@angular/router';
import { ICompany } from 'src/app/interfaces/Icompany';
import { ToastController } from '@ionic/angular';

@Component({
  selector: 'app-company-edit-form',
  templateUrl: './company-edit-form.component.html',
  styleUrls: ['./company-edit-form.component.scss'],
})
export class CompanyEditFormComponent implements OnInit {

  @Input() public companyId: String
  loading: Boolean
  company: ICompany

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private companyService: CompanyService,
    private toastController: ToastController
  ) {
    this.loading = false
  }

  companyForm = this.fb.group({
    name: ['', Validators.required]
  });
  
  bindForm() {    
    this.companyService.getOneById(this.companyId)
      .subscribe(resp => {
        this.company = resp.body.company
        this.companyForm.patchValue(this.company)
      })
  }

  ngOnInit() {
    this.bindForm()
  }

  onSubmit() {
    this.loading = true
  
    const editedCompany = {
      'company': this.companyForm.value
    }

    this.companyService.edit(this.companyId, editedCompany)
      .subscribe(resp => {
        if (resp.status == 200) {
          this.presentEditedToast()
          this.router.navigate(['/admin/companies', this.companyId])
        }
      }, error => {
        // default error message
        let message = 'Se produjo un error al crear la nueva organización/desarrollador, inténtelo nuevamente';
        
        // duplicate key
        if (error.status == 409) {
          message = 'El nombre ingresado ya se encuentra registrado.'  
        }
        this.presentErrorToast(message)
      })
  }

  async presentEditedToast() {
    const toast = await this.toastController.create({
      message: 'Organización/Desarrollador editada exitosamente',
      duration: 2200,
      color: 'tertiary',
      showCloseButton: true,
      closeButtonText: 'cerrar'
    });
    toast.present()
  }

  async presentErrorToast(message: string) {
    const toast = await this.toastController.create({
      message: message,
      // duration: 2200,
      color: 'danger',
      showCloseButton: true,
      closeButtonText: 'cerrar'
    });
    toast.present()
  }
}
